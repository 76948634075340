import { forwardRef, useCallback, useState, useEffect } from 'react'
import { Logout, UserMenu, useUserMenu, useGetIdentity, useUpdate, useRedirect, useDataProvider } from 'react-admin'
import { useMediaQuery, Drawer, IconButton, List, ListItem, MenuList, MenuItem, ListItemIcon, ListItemText, Box, Typography, Chip } from '@mui/material'

import { HorizontalMenu } from '@react-admin/ra-navigation'

import { getRole } from './common/roles'
import Tooltip from '@mui/material/Tooltip'
import { supabaseClient } from './supabase'
import { CommonDialog } from './common/Dialog'
import { useProfile } from './contexts/ProfileContext'
import { YearSelectInput } from './Design/CustomSelectInput'
import { setItemToLocalStorage, getItemFromLocalStorage } from './common/LocalStorage'

import SettingsIcon from '@mui/icons-material/Settings'
import NotificationsIcon from '@mui/icons-material/Notifications'
import VisibilityIcon from '@mui/icons-material/Visibility'
import WarningIcon from '@mui/icons-material/Warning'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import EventIcon from '@mui/icons-material/Event'
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ForumIcon from '@mui/icons-material/Forum'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import StoreIcon from '@mui/icons-material/Store'
import BusinessIcon from '@mui/icons-material/Business'
import RoomIcon from '@mui/icons-material/Room'
import SchoolIcon from '@mui/icons-material/School'
import FlagIcon from '@mui/icons-material/Flag'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import FilePresentIcon from '@mui/icons-material/FilePresent'

const leadYears = getItemFromLocalStorage('leadYears') ?? []
const notificationsCheck = getItemFromLocalStorage('notificationsCheck') ?? null

export const MyMenu = ({ role }) => {
  const inProduction = process.env.NODE_ENV === 'production'
  const isAdmin = role === getRole('admin')
  const isResponsable = role >= getRole('responsable')
  const isPubeur = role < getRole('responsable')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const menuItems = [
    { label: isPubeur ? 'Tableau de bord' : <Tooltip title="Tableau de bord"><DashboardIcon /></Tooltip>, to: "/", value: "", display: true },
    { label: isPubeur ? 'Étudiants' : <Tooltip title="Étudiants"><PeopleIcon /></Tooltip>, to: "/leads", value: "leads", display: true },
    { label: isPubeur ? 'Parents' : <Tooltip title="Parents"><FamilyRestroomIcon /></Tooltip>, to: "/parents", value: "parents", display: true },
    { label: isPubeur ? 'Évènements' : <Tooltip title="Événements"><EventIcon /></Tooltip>, to: "/events", value: "events", display: true },
    { label: <Tooltip title="Catégories d'événement"><CategoryRoundedIcon /></Tooltip>, to: "/types", value: "types", display: isResponsable },
    { label: <Tooltip title="Contacts entrants"><BrowserUpdatedIcon /></Tooltip>, to: "/inbound_contacts", value: "inbound_contacts", display: role >= getRole('coordinateur') },
    { label: <Tooltip title="Tâches"><AssignmentTurnedInIcon /></Tooltip>, to: "/tasks", value: "tasks", display: isResponsable },
    { label: <Tooltip title="Tags"><BookmarkIcon /></Tooltip>, to: "/tags", value: "tags", display: isResponsable },
    { label: <Tooltip title="Conversations"><ForumIcon /></Tooltip>, to: "/conversations", value: "conversations", display: isResponsable },
    { label: <Tooltip title="Profiles"><PersonPinIcon /></Tooltip>, to: "/profiles", value: "profiles", display: isResponsable },
    { label: <Tooltip title="Centres"><StoreIcon /></Tooltip>, to: "/centers", value: "centers", display: isAdmin },
    { label: isPubeur ? 'Lycées' : <Tooltip title="Lycées"><BusinessIcon /></Tooltip>, to: "/schools", value: "schools", display: true },
    { label: isPubeur ? 'Calendrier' : <Tooltip title="Calendrier"><CalendarMonthIcon /></Tooltip>, to: "/calendar", value: "calendar", display: true },
    { label: <Tooltip title="Sites de centres"><RoomIcon /></Tooltip>, to: "/sites", value: "sites", display: isResponsable },
    { label: <Tooltip title="Années scolaires"><SchoolIcon /></Tooltip>, to: "/lead_years", value: "lead_years", display: isAdmin },
    { label: <Tooltip title="Sources"><FlagIcon /></Tooltip>, to: "/lead_sources", value: "lead_sources", display: isAdmin },
    { label: <Tooltip title="Statut inscrit"><PlaylistAddCheckIcon /></Tooltip>, to: "/qualifications", value: "qualifications", display: isAdmin },
    { label: <Tooltip title="Brochures"><FilePresentIcon /></Tooltip>, to: "/brochures", value: "brochures", display: isResponsable && !inProduction }
  ]

  const filteredMenuItems = menuItems.filter(item => item.display)

  const menuList = (
    <List>
      {filteredMenuItems.map((item, index) => (
        <ListItem key={index} component="a" href={item.to}>
          <ListItemIcon>{item?.label?.props?.children || item.label}</ListItemIcon>
          {!isMobile && <ListItemText primary={item.value} />}
        </ListItem>
      ))}
    </List>
  )

  return isMobile ? (
    <>
      <Box sx={{ position: 'absolute', left: 20, top: 5 }}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile
      >
        <Typography variant="h6" sx={{ padding: '20px' }}>
          Menu

          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuOpenIcon />
          </IconButton>
        </Typography>
        
        {menuList}
      </Drawer>
    </>
  ) : (
    <HorizontalMenu sx={{ '& .MuiSvgIcon-root': { fontSize: '1.3rem' } }}>
      {filteredMenuItems.map((item, index) => (
        <HorizontalMenu.Item
          key={index}
          label={item.label}
          to={item.to}
          value={item.value}
        />
      ))}
    </HorizontalMenu>
  )
}

const ConfigurationDialog = ({ openConfiguration, setOpenConfiguration, onClose }) => {
  return (
    <CommonDialog open={openConfiguration} handleClose={() => { setOpenConfiguration(false); onClose() }} title="Configuration" size="xs" minHeight="50px">
      <YearSelectInput
        label="Année scolaire à afficher"
        minWidth="200px"
        options={leadYears || [
          { id: process.env.REACT_APP_CURRENT_YEAR_ID, name: '2024-2025' },
        ]}
      />
    </CommonDialog>
  )
}

const NotificationDialog = ({ open, setOpen, onClose, profileNotifications, setNumOfNotifications }) => {
  const redirect = useRedirect()
  const [update] = useUpdate()

  const handleDeleteNotification = (notificationId, index) => {
    profileNotifications.splice(index, 1); // Remove the item at the specified index

    update('profiles_notifications',
    {
      id: notificationId,
      data: { visible: false },
      previousData: { visible: true }
    })

    setNumOfNotifications(0)
  }

  const handleNotificationURL = (type, url) => {
    if (type === 'callback') {
      setOpen(false)
      redirect(url)
    }
  }

  return (
    <CommonDialog open={open} handleClose={() => { setOpen(false); onClose() }} title="Mes notifications" size="xs" minHeight="50px">
      { profileNotifications.length > 0 ? (
        <Box>
          {profileNotifications.map((notification, index) => (
            <Chip
              clickable
              key={index}
              id={`chipNotification${index}`}
              color={notification.type === 'assignation' ? 'success' : 'info'}
              onClick={() => handleNotificationURL(notification.type, notification.url)}
              onDelete={() => handleDeleteNotification(notification.id, index)}
              sx={{
                height: 'auto',
                marginBottom: '10px',
                padding: '10px 5px',
                borderRadius: '2px',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
              }}
              label={
                <>
                  <strong>{notification.title}</strong><br/>
                  {notification.message}
                </>
              }
            />
          ))}
        </Box>
      ) : <Typography variant="body1" sx={{ color: '#aaa' }}>Vous n'avez aucune notification.</Typography> }
    </CommonDialog>
  )
}

const getProfileNotifications = async (profileId, setNewNotifications) => {
  if ( ! profileId || ! setNewNotifications ) return []

  if ( notificationsCheck < Date.now() - 120000 ) return [] // refrsh every 2 minutes
  
  console.log('Init. Fetching notifications...')

  const { data, error } = await supabaseClient
    .from('profiles_notifications')
    .select('*')
    .order('created_at', { ascending: false })
    .eq('profile_id', profileId)
    .eq('visible', true)

  if ( error ) {
    console.error('Error fetch profiles_notifications: ', error)
    return []
  }

  const filteredData = data.filter(entry => {
    return !entry.viewed ? entry : false
  })

  if ( filteredData.length > 0 ) {
    setNewNotifications(true)
    setItemToLocalStorage('notificationsCheck', Date.now())
  }

  return data
}

const ConfigurationMenu = forwardRef((props, ref) => {
  const { onClose } = useUserMenu()
  const { refetch } = useGetIdentity() // to update the role grade
  const { setRoleGrade } = useProfile()

  const { role, numOfNotifications, newNotifications, profileNotifications, setProfileNotifications, setNumOfNotifications, setNewNotifications, ...rest } = props

  const [open, setOpen] = useState(false)
  const [openConfiguration, setOpenConfiguration] = useState(false)

  const handleRoleChange = (newGrade) => {
    setRoleGrade(newGrade)

    setTimeout(() => {
      refetch()
    }, 500)
  }

  const [loading, setLoading] = useState(false);
  const batchSize = 400;

  const handleLeadUpdate = () => {
    const updateLeadsInBatches = async () => {
      try {
        setLoading(true);

        // Get total number of leads
        const { data, error: countError } = await supabaseClient
          .from('leads')
          .select('id')
          .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID) // PRODUCTION ONLY

        if (countError) throw countError;

        const total = data.length;
        console.log('Total rows:', total)
        console.log('Batch size:', batchSize)

        // Use a separate variable to track processed rows locally
        let rowsProcessed = 0;
    
        // Loop through the rows in batches
        while (rowsProcessed < total) {
          console.log(`Processing rows ${rowsProcessed + 1} to ${rowsProcessed + batchSize}`)
    
          // Update event_ids for the current batch
          const { error: eventIdsError } = await supabaseClient
            .rpc('rpc_refresh_lead_event_ids', { batch_size: batchSize, setoff: rowsProcessed })
    
          if (eventIdsError) throw eventIdsError;
          
          // Update event_types for the current batch
          const { error: eventTypesError } = await supabaseClient
            .rpc('rpc_refresh_lead_event_types', { batch_size: batchSize, setoff: rowsProcessed })
    
          if (eventTypesError) throw eventTypesError;

          // Update events_qualifications for the current batch
          const { error: eventQualCleanError } = await supabaseClient
            .rpc('rpc_refresh_lead_event_qualifications', { batch_size: batchSize, setoff: rowsProcessed })
    
          if (eventQualCleanError) throw eventQualCleanError;
/*
          // Update favourited_by for the current batch
          const { error: eventFavCleanError } = await supabaseClient
            .rpc('rpc_refresh_lead_favourites', { batch_size: batchSize, setoff: rowsProcessed })
    
          if (eventFavCleanError) throw eventFavCleanError;

          // Update tag_ids for the current batch
          const { error: eventTagsCleanError } = await supabaseClient
            .rpc('rpc_refresh_lead_tag_ids', { batch_size: batchSize, setoff: rowsProcessed })
    
          if (eventTagsCleanError) throw eventTagsCleanError;

          const { error: eventSchoolsCleanError } = await supabaseClient
            .rpc('rpc_update_events_schools_names', { batch_size: batchSize, setoff: rowsProcessed });
    
          if (eventSchoolsCleanError) throw eventSchoolsCleanError;

          const { error: eventSchoolsCleanError } = await supabaseClient
            .rpc('rpc_insert_event_schools', { batch_size: batchSize, setoff: rowsProcessed });
    
          if (eventSchoolsCleanError) throw eventSchoolsCleanError;*/
    
          // Increment the local rowsProcessed variable
          rowsProcessed += batchSize;

          // Optionally, add a small delay between batches to avoid overwhelming the database
          await new Promise((resolve) => setTimeout(resolve, 500));

          console.log(`Processed ${rowsProcessed} rows of ${total}`);
          
        }
    
        console.log('Batch update complete.');
      } catch (error) {
        console.error('Error during batch update:', error);
      } finally {
        setLoading(false);
      }
    }

    updateLeadsInBatches()
  }

  /*const handleUpdateTasksInfo = () => {
    const handleBatchUpdate = async () => {
      try {
        setLoading(true);

        // Get total number of rows to process
        const { data: leads, error: fetchError } = await supabaseClient
          .from('leads')
          .select('id')
          .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

        if (fetchError) throw fetchError;

        const totalRows = leads.length;

        let processedRows = 0;

        // Process rows in batches
        while (processedRows < totalRows) {
          console.log(`Processing rows ${processedRows + 1} to ${processedRows + batchSize}`);

          // Invoke the selected RPC (momentary_assignation, last_comment, or tasks_info)
          const { error: rpcError } = await supabaseClient.rpc('rpc_refresh_tasks_info', {
            batch_size: batchSize,
            setoff: processedRows,
          });

          if (rpcError) throw rpcError;

          // Increment processed rows
          processedRows += batchSize;

          console.log(`Processed ${processedRows} rows of ${totalRows}`);

          // Add a small delay to avoid overloading the database
          await new Promise((resolve) => setTimeout(resolve, 500));  // 500ms delay
        }

        console.log('Batch update complete.');
      } catch (error) {
        console.error('Batch update error:', error);
      } finally {
        setLoading(false);
      }
    }

    // handleBatchUpdate();
  }

  const handleUpdateLastComment = () => {
    const handleBatchUpdate = async () => {
      try {
        setLoading(true);

        // Get total number of rows to process
        const { data: leads, error: fetchError } = await supabaseClient
          .from('leads')
          .select('id')
          .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

        if (fetchError) throw fetchError;

        const totalRows = leads.length;

        let processedRows = 0;

        // Process rows in batches
        while (processedRows < totalRows) {
          // Invoke the selected RPC (momentary_assignation, last_comment, or tasks_info)
          const { error: rpcError } = await supabaseClient.rpc('rpc_refresh_last_comment', {
            batch_size: batchSize,
            setoff: processedRows,
          });

          if (rpcError) throw rpcError;

          // Increment processed rows
          processedRows += batchSize;

          console.log(`Processed ${processedRows} rows of ${totalRows}`);

          // Add a small delay to avoid overloading the database
          await new Promise((resolve) => setTimeout(resolve, 500));  // 500ms delay
        }

        console.log('Batch update complete.');
      } catch (error) {
        console.error('Batch update error:', error);
      } finally {
        setLoading(false);
      }
    };

    handleBatchUpdate();
  }*/

  const resetNotifications = async () => {
    let query = []

    profileNotifications.forEach(notification => {
      const toPush = {
        id: notification.id,
        viewed: true,
      }

      query.push(toPush)
    })

    const { error } = await supabaseClient
      .from('profiles_notifications')
      .upsert(query)

    if ( error ) {
      console.error('Error updating profiles_notifications: ', error)
      return
    }
    
    setNewNotifications(false)
  }
  
  return (
    <>
      <NotificationDialog
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        profileNotifications={profileNotifications}
        setNumOfNotifications={setNumOfNotifications}
      />
      
      <ConfigurationDialog
        openConfiguration={openConfiguration}
        setOpenConfiguration={setOpenConfiguration}
        onClose={onClose}
      />

      <MenuItem
        ref={ref}
        {...rest} // It's important to pass the props to allow Material UI to manage the keyboard navigation
        to="/configuration"
        onClick={ () => {
          setOpen(true)
          resetNotifications()
        } }
      >
        <ListItemIcon>
          { ( numOfNotifications > 0 && newNotifications )
            ? <NotificationsIcon  fontSize="small" style={{ color: '#136783' }} />
            : <NotificationsIcon fontSize="small" style={{ color: '#aaa'}} />
          }
        </ListItemIcon>

        <ListItemText>Notifications</ListItemText>
      </MenuItem>

      <MenuItem
        ref={ref}
        {...rest}
        to="/configuration"
        onClick={ () => setOpenConfiguration(true) }
      >
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>

        <ListItemText>Années scolaires</ListItemText>
      </MenuItem>

      { getItemFromLocalStorage('localUserGrade') === getRole('admin') && (
        <>
          <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={ () => handleRoleChange(0) }
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Pubeur</ListItemText>
          </MenuItem>

          <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={ () => handleRoleChange(50) }
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Respo</ListItemText>
          </MenuItem>

          <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={ () => handleRoleChange(100) }
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Admin</ListItemText>
          </MenuItem>

          <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={loading ? null : handleLeadUpdate}
          >
            <ListItemIcon>
              <WarningIcon color="error" fontSize="small" />
            </ListItemIcon>

            <ListItemText>{ loading ? 'Loading...' : 'Update leads' }</ListItemText>
          </MenuItem>

          {/* <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={loading ? null : handleUpdateTasksInfo}
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>{ loading ? 'Loading...' : 'Tasks info update' }</ListItemText>
          </MenuItem>

          <MenuItem
            ref={ref}
            {...rest}
            to="/configuration"
            onClick={loading ? null : handleUpdateLastComment}
          >
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>{ loading ? 'Loading...' : 'Last comment update' }</ListItemText>
          </MenuItem> */}
        </>
      )}
    </>
  )
})

const CustomUserMenu = () => {
  const { identity } = useGetIdentity()
  const role = identity?.role?.grade
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )

  const [numOfNotifications, setNumOfNotifications] = useState(0)
  const [profileNotifications, setProfileNotifications] = useState ([])
  const [newNotifications, setNewNotifications] = useState(false)

  useEffect(() => {
    getProfileNotifications(identity?.id, setNewNotifications).then(data => {
      setProfileNotifications(data)
      setNumOfNotifications(data.length)
    }).catch(error => {
      console.error('Error:', error)
    })
  }, [identity?.id])

  const MyCustomIcon = () => isMobile ? <SettingsIcon /> : (
    <>
      { ( numOfNotifications > 0 && newNotifications )
        ? <Chip
            label={numOfNotifications}
            sx={{
              borderRadius: '15px',
              marginLeft: '5px',
              height: 'auto !important',
              '& .MuiChip-label': {
                padding: '2px 6px',
                fontSize: '.8rem',
                fontWeight: 'bold',
                color: '#136783',
              }
            }}
          />
      : null }
    </>
  )

  return (
    <UserMenu icon={<MyCustomIcon />}>
      <MenuList>
        <ConfigurationMenu
          role={role}
          numOfNotifications={numOfNotifications}
          newNotifications={newNotifications}
          profileNotifications={profileNotifications}
          setProfileNotifications={setProfileNotifications}
          setNumOfNotifications={setNumOfNotifications}
          setNewNotifications={setNewNotifications}
        />

        <Logout />
      </MenuList>
    </UserMenu>
  )
}

export default CustomUserMenu