import { useState } from 'react'
import { Link } from 'react-admin'
import { Box, Button } from '@mui/material'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

const CollapsibleFilterBox = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleBox = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <Box
      width={isCollapsed ? '4vw' : '20vw'}  // Adjust the width based on the collapsed state
      backgroundColor="#e6f2f1"
      padding={isCollapsed ? '10px 15px 0 5px' : '10px 20px 10px 15px'}
      marginTop="8vh"
      borderRadius="10px 0 10px 10px"
      color="#333"
      order="-1"
      transition="min-width 0.3s ease"  // Smooth transition when toggling
    >
      <Button onClick={toggleBox} sx={{ marginBottom: '10px', minWidth: isCollapsed && '0' }}>
        {isCollapsed ? <DoubleArrowIcon size="small" /> : 'Réduire'}
      </Button>
      
      {!isCollapsed && children}
    </Box>
  )
}

export default CollapsibleFilterBox
