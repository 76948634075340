import React, { useEffect, useState } from 'react'
import { Button, LoadingPage, useGetIdentity, useNotify, useRefresh } from 'react-admin'
import { Typography, Box, IconButton, Divider, Autocomplete, TextField } from '@mui/material'

import Tesseract from 'tesseract.js'
import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { CommonDialog } from '../common/Dialog'
import {
  processImageForReceiver,
  processImageForSender,
  processSingleImage,
  detectDarkMode,
  changeImageToGrey } from './imageProcessing'
import {
  checkAndIdentifyDate,
  checkAndRemoveExtra,
  truncateConversation,
  getMessagesFromImage,
  classifyMessages,
  mergeEqualMessages,
  mergeSeparatedMessages,
  handleDateMessages,
  handleRemovedUselessText,
  isSimilarMessage,
  assignDateToMessage,
  checkIfResponse,
  correctIsSenderMessage,
  hasSimilarMessage,
  findSimilarMessages
} from './messagesProcessing'

import CachedIcon from '@mui/icons-material/Cached'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import * as pdfjsLib from 'pdfjs-dist'
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`

let uploadState = {
  isMulti: false,
  lastFile: false,
  messages: [],
}

let convSystem = null
let conversationInfo = null

const ConversationImport = () => {
  const { identity } = useGetIdentity()
  const refresh = useRefresh()
  const notify = useNotify()

  const [open, setOpen] = useState(false)
  
  const [listOfProfiles, setListOfProfiles] = useState([])
  const [listOfLeads, setListOfLeads] = useState([])
  const [pubeurInfo, setPubeurInfo] = useState({ id: null, name: null })
  const [leadInfo, setLeadInfo] = useState({ id: null, name: null })

  const [isLoading, setIsLoading] = useState(false)

  const [messages, setMessages] = useState([])
  const [originalMessages, setOriginalMessages] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [loadingDisplay, setLoadingDisplay] = useState({ fileName: null, message: null })

  // *******************
  // Image upload
  // *******************
  
  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const fileName = file.name.replace('.jpg', '').replace('.jpeg', '').replace('.png', '')
        convSystem = 'iPhone' // only iPhone screenshots for now

        setLoadingDisplay(
          (prevState) => ({
            ...prevState,
            fileName,
            message: 'Analyse de la capture d\'écran...'
          })
        )
      }, 1500)
    
      const reader = new FileReader()
      reader.onload = async (e) => {
        try {
          const originalImageData = e.target.result
          // const isDarkMode = await detectDarkMode(originalImageData)
          const isDarkMode = false
  
          if ( isDarkMode ) { // for testing purposes
            const process1 = await changeImageToGrey(originalImageData)
            const process2 = await processSingleImage(process1)
            await extractTextUsingOCR(process2)

            // open new tab with image
            // const newTab = window.open()
            // newTab.document.body.innerHTML = `<img src="${process2}" />`
          } else {
            const processedImageDataForReceiver = await processImageForReceiver(originalImageData)
            const processedImageDataForSender = await processImageForSender(originalImageData)
            const processedImageDataForSender2 = await changeImageToGrey(processedImageDataForSender)
            await extractTextUsingOCR(processedImageDataForReceiver, processedImageDataForSender2)
            
            // open new tab with image
            // const newTab = window.open()
            // newTab.document.body.innerHTML = `<img src="${processedImageDataForSender2}" />`
          }
  
          resolve() // Resolve when everything is done
        } catch (error) {
          reject(error) // Handle any errors in processing
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const extractTextUsingOCR = async (receiverImage, senderImage) => {
    console.log('Using OCR to find text on the processed image and the original image...')

    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Extraction des messages depuis la capture d\'écran...'
      })
    )

    let filteredMessagesReceiver
    let filteredMessagesSender
    let result

    if ( ! senderImage ) { // testing purposes for now
      const resultReceiver = result = await Tesseract.recognize(receiverImage, 'fra')
      const { words } = resultReceiver.data
      const allMessages = getMessagesFromImage(words)

      console.log('Messages from single image:', allMessages);

      filteredMessagesReceiver = allMessages.filter(message => message.x < 200)
      filteredMessagesSender = allMessages.filter(message => message.x >= 200)
    }
    
    if ( senderImage ) {
      // get messages from each image (one of each side)
      const resultReceiver = await Tesseract.recognize(receiverImage, 'fra')
      const resultSender = result = await Tesseract.recognize(senderImage, 'fra')

      await Promise.all([resultReceiver, resultSender])

      const { words: wordsReceiver } = resultReceiver.data
      const { words: wordsSender } = resultSender.data

      const messagesSender = getMessagesFromImage(wordsSender)
      const messagesReceiver = getMessagesFromImage(wordsReceiver)

      filteredMessagesReceiver = messagesReceiver.filter(message => message.x < 180)
      filteredMessagesSender = messagesSender.filter(message => message.x >= 180)
    }

    // perform first clean for dates
    const messagesReceiverClean = checkAndRemoveExtra(filteredMessagesReceiver)
    const messagesSenderClean = checkAndIdentifyDate(filteredMessagesSender)

    console.log('Messages from receiver :', messagesReceiverClean);
    console.log('Messages from sender :', messagesSenderClean);

    // group all messages in the right order
    let allMessages = [...messagesSenderClean, ...messagesReceiverClean]
    allMessages.sort((a, b) => a.y - b.y)

    // perform another quick clean
    allMessages = allMessages.filter(message => {
      const lowerCaseText = message.text.trim().toLowerCase()
      return !['distribu', 'delivered', 'lu le', 'read on'].some(unwanted => lowerCaseText.startsWith(unwanted))
    })

    const truncatedAllMessages = truncateConversation(allMessages) // Filter messages to keep only those before a "+ " message

    // final data (matching with pdf extraction)
    const extractedData = [{
      pageNumber: 1,
      viewport: { width: result.data.width, height: result.data.height },
      textItems: truncatedAllMessages.map(message => ({
        str: message.text,
        transform: [1, 0, 0, 1, message.x, message.y],
        width: message.width,
        height: message.height
      }))
    }]

    parseConversation(extractedData)
  }

  // *******************
  // PDF upload
  // *******************

  const handlePDFUpload = async (file) => {
    const fileName = file.name.replace('.pdf', '')

    const extractedData = await extractTextAndPositionFromPDF(file)

    parseConversation(extractedData)
  }

  const extractTextAndPositionFromPDF = async (pdfFile) => {
    console.log('Extracting text from PDF file...')
    
    const arrayBuffer = await pdfFile.arrayBuffer()
    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise
  
    const extractedData = []
  
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber)
      const content = await page.getTextContent()
      const viewport = page.getViewport({ scale: 1 })
  
      const filteredTextItems = content.items
        .map(item => ({
          ...item,
          str: item.str.replace(/^Message\s*/, '').trim(),
        }))
        .filter(item => 
          item.str.length > 0 && // Filter out any empty strings
          !['Distribué', 'Delivered'].includes(item.str) // Exclude specific messages
        )
  
      const combinedTextItems = []
  
      filteredTextItems.forEach((item, index) => {
        const itemText = item.str
        const previousItem = combinedTextItems.length > 0 ? combinedTextItems[combinedTextItems.length - 1] : null
  
        if (itemText === '?' || itemText === '!') {
          if (previousItem) {
            previousItem.str += ' ' + itemText
          }
        } else if (itemText.startsWith(',') || itemText.startsWith('.') || itemText.startsWith(' ,') || itemText.startsWith(' .') || itemText.startsWith(':')) {
          if (previousItem) {
            previousItem.str += itemText
          }
        } else if (previousItem && previousItem.str.endsWith(',')) {
          previousItem.str += ' ' + itemText
        } else {
          combinedTextItems.push(item)
        }
      })
  
      extractedData.push({
        pageNumber,
        viewport,
        textItems: combinedTextItems.map(item => ({
          str: item.str,
          transform: item.transform,
          width: item.width,
          height: item.height,
        })),
      })
    }
  
    return extractedData
  }

  // *******************
  // Parsing
  // *******************

  const parseConversation = async (extractedData) => {
    console.log('Parsing conversation...')

    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Assignation des messages, nettoyage et association des dates...'
      })
    )
    
    const classifiedMessages = classifyMessages(extractedData)
    const mergedEqualMessages = mergeEqualMessages(JSON.parse(JSON.stringify(classifiedMessages)))
    const mergedSeparatedMessages = mergeSeparatedMessages(JSON.parse(JSON.stringify(mergedEqualMessages)))
    const identifyDateMessages = handleDateMessages(JSON.parse(JSON.stringify(mergedSeparatedMessages)))
    const identifyResponseMessages = checkIfResponse(JSON.parse(JSON.stringify(identifyDateMessages)))
    const correctedSenderResponseMessages = correctIsSenderMessage(JSON.parse(JSON.stringify(identifyResponseMessages)))
    const finalMessages = handleRemovedUselessText(JSON.parse(JSON.stringify(correctedSenderResponseMessages)))

    console.log('Conversation parsed!', finalMessages)
    
    // setOriginalMessages(finalMessages) // for resetting purposes
    uploadState.messages.push(finalMessages)

    if ( uploadState.isMulti && uploadState?.lastFile ) {
      await submitImport()
    } else {
      setMessages(finalMessages)
      setOriginalMessages(finalMessages)
    }

    if ( !uploadState.isMulti ) {
      setIsLoading(false)
    }
  }

  // *******************
  // File upload
  // *******************

  const handleFileUpload = async (event) => {
    // Access the files directly from event.target
    console.log('Importing new files...')

    const files = event.target.files

    if ( ! files.length ) return

    if ( files.length > 1 ) {
      uploadState.isMulti = true
    }
  
    // Convert FileList to an array to work with multiple files
    const fileArray = Array.from(files)
  
    setMessages([])
    setOriginalMessages([])
    setErrorMessage(null)
    setIsLoading(true)
    
    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Importation de fichiers...'
      })
    )
    
    // Iterate over the array of files
    for (let i = 0; i < fileArray.length; i++) {
      const file = fileArray[i]
      const fileType = file.type
      const isValidImage = fileType === 'image/jpeg' || fileType === 'image/png'
      const isPdf = fileType === 'application/pdf'
  
      if ( isValidImage ) {
        await handleImageUpload(file) // Upload images
      } else if ( isPdf ) {
        await handlePDFUpload(file) // Upload PDFs
      }

      // Check if this is the last file in the array
      const isLastFile = i === fileArray.length - 2
      if ( isLastFile ) {
        uploadState.lastFile = true
      }
    }
  }

  // *******************
  // Saving conversation
  // *******************

  const submitImport = async () => {
    if (!leadInfo.id) {
      resetState()
      return
    }

    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Importation... Nettoyage des doublons...'
      })
    )

    const conversationMessages = mergeMessagesWithUpdatedOrder(uploadState.messages)
    console.log('conversationMessages', conversationMessages);
    const cleanedConversationMessages = findSimilarMessages(conversationMessages)
    console.log('cleanedConversationMessages', cleanedConversationMessages);

    setLoadingDisplay(
      (prevState) => ({
        ...prevState,
        message: 'Importation... Ajouts des messages...'
      })
    )

    if ( !conversationInfo ) {
      conversationInfo = await getOrCreateConversation(convSystem)
    }
  
    if (!conversationInfo || !conversationInfo.id) return
  
    // Process all the messages in the uploadState object
    // for (let i = 0; i < uploadState.messages.length; i++) {
    //   const messageBatch = uploadState.messages[i]
  
    //   // Go through each message in the batch
    //   for (let j = 0; j < messageBatch.length; j++) {
    //     const message = messageBatch[j]
    //     const { isSenderMessage, isDate, isResponse, text: newMessageText, createdAt: newMessageCreatedAt } = message
    //     const senderType = isSenderMessage ? 'pubeur' : 'lead'
    //     const senderId = isSenderMessage ? identity?.id : leadInfo.id
  
    //     // If createdAt is not set, assign the date
    //     if (!newMessageCreatedAt) {
    //       await assignDateToMessage(j, messageBatch) // Function to set createdAt dates for messages
    //     }
  
    //     // Identify similar messages in the existing conversation
    //     const similarMessage = await (async () => {
    //       for (let existingMessage of conversationInfo.existingMessages) {
    //         const isSimilar = await isSimilarMessage(existingMessage.text, newMessageText)
    //         if (isSimilar) {
    //           return existingMessage // Return the first similar message found
    //         }
    //       }
    //       return null // No similar message found
    //     })()
  
    //     // If there's a similar message, update it or skip if the new one is shorter
    //     if (similarMessage) {
    //       if (newMessageText.length > similarMessage.text.length) {
    //         console.log(`Updating message "${similarMessage.text}" with longer one "${newMessageText}"`)
    //         await updateMessage(similarMessage.id, newMessageText, newMessageCreatedAt)
    //       } else {
    //         console.log(`Skipping shorter or equal message "${newMessageText}"`)
    //       }
    //       continue // Skip to the next message
    //     }
  
    //     // Otherwise, insert the new message
    //     const messageValues = {
    //       sender_id: senderId,
    //       sender_type: senderType,
    //       text: newMessageText,
    //       paid: !isDate && !isResponse && newMessageText.length > 20,
    //       isDate,
    //       isResponse,
    //       created_at: newMessageCreatedAt,
    //     }
  
    //     console.log('Inserting new message:', messageValues, conversationInfo.id)
    //     await insertNewMessage(messageValues, conversationInfo.id)
    //   }
    // }

    // Go through each message in the batch
    for (let i = 0; i < cleanedConversationMessages.length; i++) {
      const message = cleanedConversationMessages[i]
      const { isSenderMessage, isDate, isResponse, text: newMessageText, createdAt: newMessageCreatedAt } = message
      const senderType = isSenderMessage ? 'pubeur' : 'lead'
      const senderId = isSenderMessage ? identity?.id : leadInfo.id

      // If createdAt is not set, assign the date
      if (!newMessageCreatedAt) {
        await assignDateToMessage(i, cleanedConversationMessages) // Function to set createdAt dates for messages
      }

      // Identify similar messages in the existing conversation
      const similarMessage = await (async () => {
        for (let existingMessage of conversationInfo.existingMessages) {
          const isSimilar = hasSimilarMessage(existingMessage.text, newMessageText)

          if (isSimilar) {
            return existingMessage // Return the first similar message found
          }
        }
        return null // No similar message found
      })()

      // If there's a similar message, update it or skip if the new one is shorter
      if (similarMessage) {
        if (newMessageText.length > similarMessage.text.length) {
          console.log(`Updating message "${similarMessage.text}" with longer one "${newMessageText}"`)
          await updateMessage(similarMessage.id, newMessageText, newMessageCreatedAt)
        } else {
          console.log(`Skipping shorter or equal message "${newMessageText}"`)
        }
        continue // Skip to the next message
      }

      // Otherwise, insert the new message
      const messageValues = {
        sender_id: senderId,
        sender_type: senderType,
        text: newMessageText,
        paid: !isDate && !isResponse && newMessageText.length > 20,
        isDate,
        isResponse,
        created_at: newMessageCreatedAt,
      }

      await insertNewMessage(messageValues, conversationInfo.id)
    }
  
    // Check if this is the last file and last message in the multi-upload process
    if (!uploadState.active || uploadState.lastFile) {
      setIsLoading(false)
      notify('Conversation importée avec succès', { type: 'success' })
      resetState() // Reset the form and state after import
      refresh() // Refresh the data
    }
  }

  const getOrCreateConversation = async (convSystem) => {
    console.log('Checking if conversation already exists...')

    // Check if conversation already exists
    const { data, error } = await supabaseClient
      .from('conversations')
      .select('id')
      .eq('lead_id', leadInfo.id)
      .eq('pubeur_id', pubeurInfo.id)

    if (error) {
      console.error(error)
      return null
    }

    if (data.length > 0) {
      console.log('Conversation already exists')

      // Fetch existing messages for comparison
      const { data: existingMessages, error: messagesError } = await supabaseClient
        .from('conversation_messages')
        .select('*')
        .eq('conversation_id', data[0].id)
        .order('created_at', { ascending: true })

      if (messagesError) {
        console.error(messagesError)
        return null
      }

      return {
        id: data[0].id,
        existingMessages,
      }
    }

    // Create a new conversation if none exists
    console.log('Creating a new conversation...')

    const { data: newConversation, error: newConversationError } = await supabaseClient
      .from('conversations')
      .insert({
        lead_id: leadInfo.id,
        pubeur_id: pubeurInfo.id,
        current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
        system: convSystem
      })
      .select()

    if (newConversationError) {
      console.error(newConversationError)
      return null
    }

    return {
      id: newConversation[0].id,
      existingMessages: [],
    }
  }

  const mergeMessagesWithUpdatedOrder = (messages) => {
    return messages.reduce((acc, cur) => [...acc, ...cur], [])
    .map((message, index) => ({ ...message, order: index }))
  }

  const insertNewMessage = async (messagesValues, conversationId) => {
    const { error } = await supabaseClient
      .from('conversation_messages')
      .insert({ ...messagesValues, conversation_id: conversationId })

    if (error) {
      console.error('Error inserting message:', error)
    }
  }

  const updateMessage = async (messageId, newText, newCreatedAt) => {
    const { error } = await supabaseClient
      .from('conversation_messages')
      .update({ text: newText, created_at: newCreatedAt })
      .eq('id', messageId)

    if (error) {
      console.error('Error updating message:', error)
    }
  }

  const resetState = () => {
    setPubeurInfo({ id: null, name: null })
    setLeadInfo({ id: null, name: null })
    setMessages([])
    setOriginalMessages([])
    setOpen(false)
    setIsLoading(false)
  }

  // messages operations
  const resetToOriginalMessages = () => {
    setMessages(originalMessages)
  }

  const deleteCurrentMessage = (index) => {
    const newMessages = [...messages]
  
    // Check if the previous message is a date and remove it as well
    if (index > 0 && newMessages[index - 1].isDate && newMessages[index + 1].isDate) {
      newMessages.splice(index - 1, 1)
      index-- // Adjust the index to account for the removed date
    }
  
    // Remove the current message
    newMessages.splice(index, 1)
  
    setMessages(newMessages)
  }

  // hooks to get pubeurs and leads
  useEffect(() => {
    if ( ! pubeurInfo.id ) return

    const fetchLeads = async () => {
      const { data, error } = await supabaseClient
        .from('leads')
        .select()
        .eq('assigned_pubeur', pubeurInfo.id)
      
      if (error) {
        console.error(error)
        return
      }

      const leads = data.map(lead => ({ id: lead.id, name: `${lead.first_name ?? ''} ${lead.last_name ?? ''}` }))

      setListOfLeads(leads)
    }

    fetchLeads()
  }, [pubeurInfo])

  useEffect(() => {
    const fetchProfiles = async () => {
      if ( identity?.role?.grade === getRole('admin') ) {
        const { data, error } = await supabaseClient
          .from('profiles')
          .select()
        
        if (error) {
          console.error(error)
          return
        }

        const profiles = data.map(profile => ({ id: profile.id, name: `${profile.first_name ?? ''} ${profile.last_name ?? ''}` }))
        setListOfProfiles(profiles)
        return
      }

      const { data, error } = await supabaseClient
        .from('profiles')
        .select()
        .eq('center_id', identity?.center?.id)
      
      if (error) {
        console.error(error)
        return
      }

      const profiles = data.map(profile => ({ id: profile.id, name: profile.first_name + ' ' + profile.last_name }))

      setListOfProfiles(profiles)
    }

    fetchProfiles()
  }, [])

  return (
    <>
      <CommonDialog
        title="Importer une conversation"
        open={open}
        handleClose={() => {
          setMessages([])
          setPubeurInfo({ id: null, name: null })
          setLeadInfo({ id: null, name: null })
          setOpen(false)
          setIsLoading(false)
        }}
        size="sm"
        icon={<OpenInNewIcon />}
      >
        { !isLoading && (
          <>
            { !uploadState.isMulti && messages.length === 0 && (
              <>
                { errorMessage && (
                  <Typography variant="body1" color="error" gutterBottom>
                    { errorMessage }
                  </Typography>
                )}
                
                <h3>Importer une conversation SMS</h3>

                <Autocomplete
                  disablePortal
                  options={listOfProfiles}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setPubeurInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label="Pubeur" variant="outlined" />
                    )
                  }}
                />

                { pubeurInfo?.id && (
                  <Autocomplete
                    disablePortal
                    options={listOfLeads}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setLeadInfo({ id: value?.id ?? null, name: value?.name ?? null })}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label="Contact étudiant" variant="outlined" />
                      )
                    }}
                  />
                )}
              </>
            )}

            { messages.length === 0 && pubeurInfo.id && leadInfo.id && (
              <>
                <h3>Importer un fichier (capture d'écran ou conversation PDF)</h3>

                <input
                  type="file"
                  onChange={handleFileUpload}
                  multiple
                  style={{
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    width: '90%',
                    boxSizing: 'border-box',
                    marginTop: '20px'
                  }}
                />
              </>
            )}
          </>
        )}
        
        {/* {messages.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h5" gutterBottom>
              Conversation avec { leadInfo?.name ?? 'un contact' }
              <IconButton
                size="small"
                sx={{
                  ml: 2,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => resetToOriginalMessages()}
              >
                <CachedIcon />
              </IconButton>
            </Typography>

            { errorMessage && (
              <Typography variant="body1" color="error" gutterBottom>
                { errorMessage }
              </Typography>
            )}

            <Divider orientation="horizontal" aria-hidden="true" sx={{ mt: 1, mb: 1 }} />
            
            {messages.map((message, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent={
                  message.isDate
                    ? 'center'
                    : message.isSenderMessage
                    ? 'flex-end'
                    : 'flex-start'
                }
                sx={{
                  fontWeight: message.isDate ? 'bold' : 'normal',
                  fontSize: message.isDate ? '.75rem' : '.95rem',
                }}
              >
                {!message.isSenderMessage && !message.isDate && (
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => deleteCurrentMessage(index)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}

                <Box
                  component="div"
                  style={{
                    textAlign: message.isDate
                      ? 'center'
                      : message.isSenderMessage
                        ? 'right'
                        : 'left',
                    backgroundColor: message.isDate
                      ? 'transparent'
                      : message.isSenderMessage
                        ? message.isResponse
                          ? '#80a0b2'
                          : '#56a39f'
                        : '#dcdcdc',
                    color: message.isDate ? '#999' : message.isSenderMessage ? 'white' : 'black',
                    padding: '10px',
                    margin: message.isDate ? '15px 5px 5px 5px' : message.isResponse ? '0 5px 0 0' : '5px',
                    borderRadius: '10px',
                    maxWidth: message.isDate ? '100%' : message.isResponse ? '50%' : '60%',
                    fontSize: message.isDate ? undefined : message.isResponse ? '.75rem' : '.95rem',
                  }}
                >
                  {message.text}
                </Box>

                {message.isSenderMessage && !message.isDate && (
                  <IconButton
                    size="small"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    onClick={() => {
                      deleteCurrentMessage(index)
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
              </Box>
            ))}

            <Button
              label="Importer la conversation"
              variant="contained"
              color="primary"
              onClick={() => submitImport()}
              sx={{ mt: 4, mb: 4, p: 1.5 }}
              fullWidth
            />

            <Divider orientation="horizontal" aria-hidden="true" sx={{ mb: 4 }} />

            <Typography variant="body2" color="textSecondary">
              Importer un autre fichier
            </Typography>
            
            { isLoading ? (
              <LoadingPage />
            ) : (
              <input
                type="file"
                multiple
                onChange={handleFileUpload}
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  width: '90%',
                  boxSizing: 'border-box',
                  marginTop: '20px'
                }}
              />
            )}
          </div>
        )} */}

        { isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            <Typography variant="h6" mt={4} mb={4}>
              { loadingDisplay.fileName && `Chargement de ${loadingDisplay.fileName}` }
            </Typography>

            <Typography variant="body1">
              { loadingDisplay.message }
            </Typography>
          </Box>
        )}
      </CommonDialog>

      <Button
        startIcon={<OpenInNewIcon />}
        label="Importer une conversation"
        variant="outlined"
        color="primary"
        onClick={() => setOpen(true)}
      />
    </>
  )
}

export default ConversationImport