import { useEffect, useState, useMemo } from 'react'
import { AutocompleteInput, Button, CheckboxGroupInput, Loading, SaveButton, SelectInput, SimpleForm, TextInput, required, useGetIdentity, useNotify } from 'react-admin'
import { Typography, Grid, Chip, Autocomplete, TextField as MuiTextField } from '@mui/material'

import { getRole } from '../../common/roles'
import sendMail from '../../common/SendMail'
import { formatDate } from '../../common/date'
import { supabaseClient } from '../../supabase'
import fetchEvents from '../../common/fetchEvents'
import { CommonDialog } from '../../common/Dialog'
import lighterColor from '../../common/lighterColor'
import { getClassNameById } from '../../common/classes'
import { getOptionsNameById } from '../../common/options'
import { getSpecialitiesNameById } from '../../common/specialities'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../../common/LocalStorage'

import CircleIcon from '@mui/icons-material/Circle'
import CircularProgress from '@mui/material/CircularProgress'
import FastForwardIcon from '@mui/icons-material/FastForward'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const TaskActionsButtons = ({ parcoursTree, setParcoursTree, refresh, ...props }) => {
  const { identity } = useGetIdentity()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const notify = useNotify()

  const localCurrentYear = process.env.REACT_APP_CURRENT_YEAR
  const currentTimestamp = new Date().toISOString()

  const centerTags = getItemFromLocalStorage('centerTags')
  const eventsInfo = getItemFromLocalStorage('eventsInfo')

  const [actionsToShow, setActionsToShow] = useState([])
  const [openActionDialog, setOpenActionDialog] = useState(false)
  const [actionToEdit, setActionToEdit] = useState({})

  const [tagsChoices, setTagsChoices] = useState([])
  const [selectedTags, setSelectedTags] = useState([])

  const [events, setEvents] = useState([])
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [eventQualifications, setEventQualifications] = useState([])

  const [loadingSaveTags, setLoadingSaveTags] = useState(false)
  const [commentPubeur, setCommentPubeur] = useState(null)

  // tags management
  const saveCurrentTags = async (tagsIds, stopRGPD, isInterested, isNotInterested) => {
    setLoadingSaveTags(true)

    const { data, error } = await supabaseClient 
      .from('leads')
      .update({
        tags_ids: tagsIds,
        stop_rgpd: stopRGPD,
        interested: isInterested,
        not_interested: isNotInterested,
      })
      .eq('id', props.leadRecord.id)
      .select()

    if ( error ) {
      console.error('Erreur lors de la mise à jour des tags')
    }

    props.leadRecord.tags_ids = tagsIds
    refresh()

    setTimeout(() => {
      setLoadingSaveTags(false)
    }, 1000)
  }

  const handleTagChange = (event, newValue) => {
    const lowerCaseTags = newValue.map(tag => tag.name.toLowerCase())

    const stopRGPDSelected = lowerCaseTags.includes('stop rgpd')
    let interestedSelected = lowerCaseTags.includes('intéressé(e) par la prépa')
    let notInterestedSelected = lowerCaseTags.includes('pas intéressé(e) par la prépa')
  
    // If both "intéressé(e) par la prépa" and "Pas intéressé(e) par la prépa" are selected,
    // keep only the last selected one and unselect the other
    if (interestedSelected && notInterestedSelected) {
      const lastSelectedTag = newValue[newValue.length - 1]
      const unselectedTagName =
        lastSelectedTag.name.toLowerCase() === 'intéressé(e) par la prépa'
          ? 'pas intéressé(e) par la prépa'
          : 'intéressé(e) par la prépa';
      newValue = newValue.filter(tag => tag.name.toLowerCase() !== unselectedTagName)

      if (unselectedTagName === 'intéressé(e) par la prépa') {
        interestedSelected = false
        notInterestedSelected = true
      } else {
        interestedSelected = true
        notInterestedSelected = false
      }
    }
  
    // Filter selected tags to match the structure of options
    const filteredSelectedTags = newValue.filter(tag =>
      tagsChoices.some(option => option.id === tag.id)
    )
  
    setSelectedTags(filteredSelectedTags)

    const newTagsIds = newValue.map(tag => tag.id)

    saveCurrentTags(newTagsIds, stopRGPDSelected, interestedSelected, notInterestedSelected)
  }

  const getTagsChoices = async () => {
    if ( ! props.leadRecord ) return

    let tagsData = centerTags?.tags || []

    if ( tagsChoices?.length === 0 && ( isAdmin || ! centerTags ) ) {
      console.log('Getting tags choices in the lead...');
      
      const { data, error } = await supabaseClient
        .from('tags')
        .select('*')
        .eq('center_id', props.leadRecord?.center_id)

      if ( error ) {
        console.error('Erreur lors de la récupération des qualifications')
      }

      if ( ! data ) return

      const tagChoices = data.map(tag => ({
        id: tag.id,
        name: tag.name,
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
    
      setTagsChoices(tagChoices)
      setItemToLocalStorage('centerTags', { tags: data, updatedAt: Date.now() })

      tagsData = data
    } else {
      setTagsChoices(tagsData)
    }

    if ( props.leadRecord?.tags_ids ) {
      console.log('Setting selected tags in the lead...', props.leadRecord?.tags_ids);
      
      const selectedTag = tagsData?.filter(tag => props?.leadRecord?.tags_ids.includes(tag.id)).map(tag => ({
        id: tag.id,
        name: tag.name,
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
  
      setSelectedTags(selectedTag)
    }
  }

  // events management
  const getEvents = async () => {
    // fech events
    const events = await fetchEvents(identity?.center?.id, identity, currentTimestamp)

    // fetch qualifications
    const { data, error } = await supabaseClient
      .from('types_qualifications')
      .select('id,name')

    if (error) {
      console.error('Error getting qualifications for one event: ', error)
    }

    for ( const event of events ) {
      if ( ! event?.qualifications_ids ) continue

      let qualificationInfo = []
      for ( const qualificationId of event.qualifications_ids ) {
        const qual = data.filter(qualification => qualification.id === qualificationId)

        qualificationInfo.push(qual)
      }
      
      event.qualificationInfo = qualificationInfo ?? []
    }

    setEvents(events)
  }

  const handleEventChoice = (eventId) => {
    if ( ! eventId ) {
      setEventQualifications([])
      return
    }

    // find event qualification in the eventsInfo list
    const thisEvent = events?.find(event => event.id === eventId)
    const flattenedEventQualifications = thisEvent?.qualificationInfo?.flat()

    setEventQualifications(flattenedEventQualifications)
    setSelectedEventId(eventId)
  }

  const saveEventQualifications = async (event_qualifications) => {
    if ( ! event_qualifications ) return

    // update lead_events qualifications or insert the lead_event with qualifications
    const insertEventQualifications = async (eventId) => {
      if ( ! eventId ) return

      const { data, error } = await supabaseClient
        .from('lead_events')
        .select('qualifications')
        .eq('lead_id', props.leadRecord?.id)
        .eq('event_id', eventId)
        .eq('current_year_id', localCurrentYear)

      if ( error ) {
        console.error('Erreur lors de la récupération de l\'événement', error, eventId)
      }

      let qualificationInfo = []
      // find the qualifications selected in eventQualifications, then add them to qualificationInfo
      for ( const qual of event_qualifications ) {
        const foundQual = eventQualifications.find(eventQual => eventQual.id === qual)
        if ( foundQual ) qualificationInfo.push(foundQual)
      }

      if ( data[0] && qualificationInfo.length > 0 ) {
        // update the qualifications if some are already set
        let updatedQualifications = data[0]?.qualifications || {}

        for (const qual of qualificationInfo) {
          updatedQualifications = {
            ...updatedQualifications,
            [qual.name]: {
              id: qual.id,
              name: qual.name
            }
          }
        }

        const { error } = await supabaseClient
          .from('lead_events')
          .update({ qualifications: updatedQualifications })
          .eq('event_id', eventId)
          .eq('lead_id', props.leadRecord.id)

        if ( error ) {
          console.error('Erreur lors de la mise à jour des qualifications de l\'événement', error)
        }

        return
      }

      let newQualifications = {}
      for (const qual of qualificationInfo) {
        newQualifications = {
          ...newQualifications,
            [qual.name]: {
              id: qual.id,
              name: qual.name
            }
        }
      }

      const { error: errorCreateNewLeadEvent } = await supabaseClient
        .from('lead_events')
        .insert({
          lead_id: props.leadRecord.id,
          event_id: eventId,
          qualifications: newQualifications,
          current_year_id: localCurrentYear
        })

      if ( errorCreateNewLeadEvent ) {
        console.error('Erreur lors de l\'insertion des qualifications de l\'événement', errorCreateNewLeadEvent)
      }

      return
    }

    insertEventQualifications(selectedEventId)
  }

  // task management
  const resetTask = async () => {
    const { data: tasksData, error: tasksError } = await supabaseClient
      .from('tasks_parcours')
      .select('tree')
      .eq('task_id', props.record.task_id)

    if (tasksError) {
      console.error('Error fetching tasks parcours', tasksError)
      return
    }

    setParcoursTree(tasksData[0].tree)

    const { error } = await supabaseClient
      .from('lead_tasks')
      .update({ tree: tasksData[0].tree, progress: null, is_waiting: null, callback: null })
      .eq('id', props.record.id)

    if (error) {
      console.error('Error resetting task', error)
      return
    }
  }

  const updateWaitingTime = async (isWaiting, callback = null) => {
    console.log('updating waiting time');

    const updateTask = async () => {
      const { error } = await supabaseClient
        .from('lead_tasks')
        .update({ is_waiting: isWaiting, callback: callback })
        .eq('id', props.record.id)

      if (error) {
        console.error('Error updating task is_waiting', error)
        return
      }
    }

    await updateTask()
  }

  const flattenTree = (node, parentId = null) => {
    let actions = [{ ...node, parentId }]

    if (node.children && node.parentId !== null) {
      node.children.forEach(child => {
        actions = actions.concat(flattenTree(child, node.id))
      })
    }

    return actions
  }

  const evaluateActionsDisplay = (flattenedActions) => {
    const displayActions = []
    let currentIndex = 0 // Start with the first action

    let safetyCounter = 0
    const maxIterations = flattenedActions.length * 2
  
    while (currentIndex < flattenedActions.length && safetyCounter < maxIterations ) {
      safetyCounter++
      const currentAction = flattenedActions[currentIndex]

      if ( ! currentAction ) break

      displayActions.push(currentAction)

      if ( ! currentAction.done && currentAction.result === null ) break

      if ( currentAction.done ) {
        const nextActionIndex = flattenedActions.findIndex(action =>
          action.parentId === currentAction.id && (action.condition === currentAction.result || (action.condition === null && action.action.name === 'Traité'))
        )

        if ( nextActionIndex !== -1 ) {
          const nextAction = flattenedActions[nextActionIndex]

          // Check if the next action is "En attente" and if the callback time has expired
          if ( nextAction.action.name === 'En attente' && nextAction.callback ) {
            const parentTime = new Date(currentAction.dateAction).getTime()
            const currentTime = new Date().getTime()
            const hoursDiff = (currentTime - parentTime) / 36e5 // milliseconds to hours

            // callback time has expired
            if ( hoursDiff > nextAction.callback ) {
              // add update is_waiting to false
              updateWaitingTime(false, null)

              currentIndex = nextActionIndex + 1 // Skip the "En attente" action
              continue
            }
          }

          currentIndex = nextActionIndex; // Move to the next action
        } else break
      } else currentIndex++
    }

    return displayActions
  }

  const updateActionInTree = async (node, actionId, result, comment, leadId, manualClose, skipWaitingTime) => {
    if ( ! node ) return

    // update the tree from the action being edited
    if (node.id === actionId && !manualClose) {
      console.log('updating on current branch...');

      let children = node.children

      // if result is true and the action has 1 children "Traité", then done: true
      if ( result && children && children.length === 1 && children[0].action.name === 'Traité' ) {
        children = node.children.map(child => {
          return { ...child, done: true, dateAction: new Date() }
        })
      }

      // (else) if result is true and the action has 2 children, then update the one that meets the condition with done: true
      if ( result && children && children.length === 2 ) {
        if ( result && (
          ( children[0].condition === true && children[0].action.name === 'Traité' )
          ||
          ( children[1].condition === true && children[1].action.name === 'Traité' )
         ) ) {
          // update the one that meets the condition
          children = node.children.map(child => {
            return { ...child, done: true, dateAction: new Date() }
          })
        }
      }

      // program a callback time and send a mail to the pubeur
      if ( ! result ) {
        console.log('result is false or null');
        console.log('check if next branch is "En attente"');

        if ( node.children &&
          ( ( node.children[0]?.action?.name === 'En attente' && node.children[0]?.callback && node.children[0]?.done === false )
          ||
          ( node.children[1]?.action?.name === 'En attente' && node.children[1]?.callback && node.children[1]?.done === false ) )
        ) {
          console.log('Check is callback was set up');
          // update is_waiting to true and add the callback time
          const callBackHours = node.children[0]?.callback ?? node.children[1]?.callback
          const now = new Date()
          const timeStampPlusCallBack = new Date(now.getTime() + callBackHours * 60 * 60 * 1000).toISOString()

          // check if task callback is passed
          const checkTaskCallback = async () => {
            const { data, error } = await supabaseClient
              .from('lead_tasks')
              .select('is_waiting, callback')
              .eq('id', props.record.id)

            if ( error ) {
              console.error('error on checkTaskCallback')
            }

            return {
              res: data[0].is_waiting,
              callback: data[0].callback
            }
          }

          const callbackSetUp = await checkTaskCallback()

          console.log('callbackSetUp', callbackSetUp);

          // if no callback set up yet
          if ( callbackSetUp?.res ) {
            console.log('Callback was already set up');
            // if callback is over
            if ( callbackSetUp?.callback > now ) {
              console.log('Callbakc is over: we email the pubeur');

              const getPubeurInfo = async () => {
                const { data: leadData, error } = await supabaseClient
                  .from('leads')
                  .select('id, first_name, last_name, profile: assigned_pubeur(*)')
                  .eq('id', leadId)

                if (error) {
                  console.error('Error fetching lead data in updateActionInTree: ', error)
                  return
                }

                return leadData[0]
              }

              // get the pubeur info
              const leadData = await getPubeurInfo().then(data => data)
              const pubeurInfo = leadData?.profile

              // send mail informing of the task availability
              if ( pubeurInfo ) {
                let messageHTML = `
                  <h4>Vous pouvez continuer votre tâche sur le contact suivant :</h4>

                  <ul>
                    <li><a href="https://forma-contacts.fr/leads/${leadData.id}">${leadData.first_name} ${leadData.last_name}</a></li>
                  </ul>
                `

                const messageTEXT = `Vous pouvez continuer votre tâche sur le contact suivant :\n${leadData.first_name} ${leadData.last_name}\n`

                const now = new Date()
                const futureDate = formatDate(new Date(now.getTime() + ( node.children[0]?.callback ?? node.children[1]?.callback ) * 60 * 60 * 1000))

                sendMail ({
                  identity: pubeurInfo,
                  subject: `Votre tâche est à nouveau disponible`,
                  messageHTML: messageHTML,
                  messageTEXT: messageTEXT,
                  date: futureDate, // a cron job will send the mail at this date and time
                  sendNow: false
                })
              }
            }
          } else {
            console.log('Callbakc was not set up, we set up to:', timeStampPlusCallBack);
            await updateWaitingTime(true, timeStampPlusCallBack)
          }
        }

        console.log('Conditions updated for node "En attente", continue...')
      }

      // if we skip waiting time
      if ( skipWaitingTime && node.action.name === 'En attente' ) {
        console.log('skipWaitingTime');

        await updateWaitingTime(false, null)

        notify('L\'attente a été passée avec succès', {type: 'success'})

        return { ...node, done: true, callback: null, result: null, children: children, comment: comment, dateAction: new Date()}
      }

      console.log('task is simply updated as done');

      // else we update the done and dateAction of the current node
      // the done and result will determine the next action to display
      return { ...node, done: true, result: result, children: children, comment: comment, dateAction: new Date()}
    }

    // or continue to map over the children
    if (node.children) {
      if ( manualClose ) {
        // if manualClose, update all nodes with done: true and success: true
        const updatedChildren = await Promise.all(
          node.children.map(child => {
            const children = child.children

            if ( ! children ) {
              child.done = true
              child.success = true
              return child
            }
            
            if ( children.length === 1 ) {
              children[0] = { ...children[0], done: true, dateAction: new Date() }
              child.result = null
            }

            if ( children.length === 2 ) {
              if ( children[0].action.name === 'Traité' && children[0].condition === true ) {
                children[0] = { ...children[0], done: true, result: true, dateAction: new Date() }
              } else if ( children[1].action.name === 'Traité' && children[1].condition === true ) {
                children[1] = { ...children[1], done: true, result: true, dateAction: new Date() }
              } else {
                child.result = null
              }
            }

            child = { ...child, done: true, success: true, dateAction: new Date() }
            return updateActionInTree(child, actionId, child.result, comment, leadId, manualClose, skipWaitingTime)
          })
        )

        return { ...node, children: updatedChildren }
      }

      const updatedChildren = await Promise.all(
        node.children.map(child => updateActionInTree(child, actionId, result, comment, leadId, manualClose, skipWaitingTime))
      )

      return { ...node, children: updatedChildren }
    }

    return node
  }

  const updateStopRGPD = async () => {
    const { error } = await supabaseClient
      .from('leads')
      .update({ stop_rgpd: true })
      .eq('id', props.leadRecord.id)

    if ( error ) {
      console.error('Erreur lors de la mise à jour du stop RGPD')
    }

    props.leadRecord.stop_rgpd = true

    // also add tag "Stop RGPD" to the lead
    const stopRGPDTag = tagsChoices?.find(tag => tag.name.toLowerCase() === 'stop rgpd')

    if ( stopRGPDTag ) {
      const newTagsIds = [...props.leadRecord.tags_ids, stopRGPDTag.id]

      saveCurrentTags(newTagsIds, true, false, false)
    }
  }

  const handleSubmit = async (values) => {
    if ( values.event_qualifications && values.event_qualifications.length > 0 ) {
      saveEventQualifications(values.event_qualifications)
    }
    
    const leadId = values.lead_id
    const result = values.status === 1 ? true : values.status === -1 ? false : null
    const comment = values.comment ?? null
    const manualClose = values.manualClose ?? false
    const skipWaitingTime = values.skipWaitingTime ?? false
    const branchToEdit = values.branchToEdit ?? null // is case of skipWaitingTime
    const updatedTree = await updateActionInTree(parcoursTree, branchToEdit ?? actionToEdit.id, result, comment, leadId, manualClose, skipWaitingTime)

    await Promise.all([updatedTree])

    const { error } = await supabaseClient
      .from('lead_tasks')
      .update({ tree: updatedTree })
      .eq('id', props.record.id)
    
    if (error) {
      console.error('Error updating task actions', error)
      return
    }

    if ( comment ) {
      const taskComment = `*TACHE* ${comment}`

      const { error: errorAddComment } = await supabaseClient
        .from('lead_comments')
        .insert({
          lead_id: leadId,
          profile_id: identity?.id,
          comment: taskComment,
          event_id: null
        })
        
      if (errorAddComment) {
        console.error('Error inserting task comment in comments', errorAddComment)
        return
      }
    }
    
    setParcoursTree(updatedTree)
    setOpenActionDialog(false)
    setEventQualifications([])
    setEvents([])
    refresh()
  }

  useEffect(() => {
    const flattenedActions = flattenTree(parcoursTree)
    const actionsToDisplay = evaluateActionsDisplay(flattenedActions)
    
    setActionsToShow(actionsToDisplay)
  }, [parcoursTree])

  useEffect(() => {
    getTagsChoices()

    if ( isAdmin || ! eventsInfo ) {
      getEvents()
    } else {
      setEvents(eventsInfo.events)
    }
  }, [props.leadRecord])

  const handleOpenActionDialog = (branch) => {
    setOpenActionDialog(true)
    setActionToEdit(branch)
  }

  return (
    <>
      <CommonDialog
        open={openActionDialog}
        handleClose={() => setOpenActionDialog(false)}
        title={`Action : ${actionToEdit?.action?.name}`}
        key={`dialogButton-${actionToEdit?.id}`}
        size="md"
        widthInPx="70vw" // of the size of the dialog
        minHeight="200px"
      >
        <SimpleForm
          toolbar={false}
          sx={{ m: 0, p: 0 }}
          filter={{ lead_id: props.leadRecord?.id, task_action_id: actionToEdit?.id, profile_id: props.identity?.id }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6} sx={{ borderRight: '1px solid #ccc', pr: 2, pb: 2 }}>
              <Typography variant="body1" mb={2} color={actionToEdit?.detail ? `textPrimary` : `textSecondary`}>Détails : {actionToEdit?.detail ?? `Pas de précisions pour cette action.`}</Typography>
            
              {/* { actionToEdit?.actions?.name === 'Programmer un rendez-vous' && (
                // use Calendar in the future
                <>
                  <DateInput source="callback" parse={(date) => (date ? date.toISOString() : null)} label="Date du rendez-vous" fullWidth />
                  <TimeInput source="timeinput" parse={(time) => (time ? time.toISOString() : null)} label="Heure du rendez-vous" fullWidth />
                </>
              )}

              { actionToEdit?.actions?.name !== 'Programmer un rendez-vous' && () } */}

              <SelectInput
                source="status"
                label="Statut / Résultat"
                variant="outlined"
                choices={
                  (() => {
                    if (!actionToEdit.children || actionToEdit.children.length === 0) {
                      return [];
                    }

                    const actionChoices = actionToEdit.action.choices || [
                      { id: 1, name: 'Succès' },
                      { id: -1, name: 'Échec' },
                      { id: 2, name: 'Continuer' }
                    ]

                    return actionToEdit.children.map(child => {
                      if (child.condition === null && child.action.name === 'Traité') {
                        return { id: 1, name: 'Terminer la tâche' };
                      }

                      if ( ! actionChoices || !Array.isArray(actionChoices) ) return []
                      
                      if (child.condition === true) {
                        return actionChoices?.find(choice => choice?.id === 1)
                      } else if (child.condition === false) {
                        return actionChoices?.find(choice => choice?.id === -1)
                      } else {
                        return actionChoices?.find(choice => choice?.id === 2)
                      }
                    }).filter(choice => choice !== undefined);
                  })()
                }
                validate={[required()]}
                fullWidth
              />

              <TextInput source="comment" label="Ajouter un commentaire" variant="outlined" onChange={(e) => setCommentPubeur(e.target.value)} fullWidth />

              <AutocompleteInput
                label="Choisir un événement à mettre à jour"
                source="event"
                variant="outlined"
                choices={events?.map(event => ({ id: event.id, name: event.name }))}
                isOptionEqualToValue={(option, value) => option.label === value.name}
                filterToQuery={q => ({ 'name@ilike': q })}
                sort={{ field: 'name', order: 'ASC' }}
                onChange={(value, event) => handleEventChoice(value)}
              />

              { eventQualifications && eventQualifications.length > 0 && (
                <CheckboxGroupInput
                  source="event_qualifications"
                  label="Remplir / mettre à jour"
                  choices={eventQualifications}
                  mt={0}
                />
              )}

              <SaveButton variant="contained" label="Valider" onClick={() => setOpenActionDialog(false)} fullWidth />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" color="textSecondary" mb={2}>Informations contact</Typography>

              { props.leadRecord ? (
                <>
                  <p style={{ fontSize: '.9rem', lineHeight: '1.5rem' }}>
                    <b>Contact :</b> {`${props.leadRecord?.first_name} ${props.leadRecord?.last_name}`}<br/>
                    <b>Email :</b> {props.leadRecord?.email ?? 'Non renseigné'}<br/>
                    <b>Téléphone :</b> (+33) {props.leadRecord?.phone?.replace('+33', '') ?? 'Non renseigné'}<br/>
                    <b>Classe :</b> {getClassNameById(props.leadRecord?.class_id) ?? 'Non renseignée'}<br/>
                    <b>Formation :</b> {getSpecialitiesNameById(props.leadRecord?.options) ?? 'Non renseignée'}<br/>
                    <b>Options souhaitées :</b> {getOptionsNameById(props.leadRecord?.center_id, props.leadRecord?.desired_options) ?? 'Non renseigné'}
                  </p>

                  <Autocomplete
                    multiple
                    options={tagsChoices}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    onChange={handleTagChange}
                    sx={{ marginBottom: '1em' }}
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        key={params.id}
                        variant="outlined"
                        label="Sélectionner un ou plusieurs tags"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingSaveTags ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={`li-tag-${option.id}`} sx={{ padding: '2px', backgroundColor: `#${option.color} !important` }}>
                          <CircleIcon sx={{ fontSize: '1rem', marginRight: '10px', color: `#${option.color}` }} />
                          {option.name}
                        </li>
                      );
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                        {...getTagProps({ index })}
                          variant="outlined"
                          label={option.name}
                          key={`chip-tag-${option.id}`}
                          sx={{
                            color: `#${option.color} !important`,
                            backgroundColor: `#${option.colorLight} !important`,
                            fontWeight: 500,
                            border: 'none',
                            fontSize: '12px',
                            margin: '1px',
                            padding: '1px',
                            height: '24px'
                          }}
                        />
                      ))
                    }
                    value={selectedTags}
                  />
                  
                  <p style={{ color: 'darkred', textAlign: 'center', fontSize: '.9rem' }}>
                    <Button
                      label="Mettre fin à la tâche"
                      variant="contained"
                      color="error"
                      sx={{ mt: 2, mb: .5 }}
                      onClick={() => {
                        handleSubmit({
                          lead_id: props.leadRecord.id,
                          result: true,
                          comment: commentPubeur ? `Tâche clôturée : ${commentPubeur}` : 'Tâche clôturée : STOP RGPD / faux numéro',
                          manualClose: true
                        })
                        updateStopRGPD()
                      }}
                    />
                    <br/>Uniquement si le contact ne souhaite plus être contacté / faux numéro
                  </p>
                </>
              ) : `Chargement...` }
            </Grid>
          </Grid>
        </SimpleForm>
      </CommonDialog>

      { actionsToShow ? actionsToShow.map((branch) => {
        if ( ! branch.parentId || ! branch.action ) return null
        
        return (
          <div key={`fragment-${branch.id}`}>
            <Button
              key={`actionButton-${branch.id}`}
              label={
                branch.action?.name === 'Traité'
                  ? 'Tâche terminée'
                  : branch.action?.name === 'En attente'
                    ? 'En attente'
                    : branch.action?.name
              }
              startIcon={branch.done || branch.action?.name === 'Traité' ? <CheckCircleOutlineIcon /> : null}
              variant="contained"
              fullWidth
              style={{
                marginBottom: '.6em',
                backgroundColor: branch.done || branch.action?.name === 'Traité'
                  ? branch.result !== false || branch.action?.name === 'Traité'
                    ? 'lightgreen'
                    : '#ffbe4f'
                  : null
              }}
              disabled={branch.done || branch.action?.name === 'Traité' || branch.action?.name === 'En attente'}  // Disable if action is done or action is 'Traité'
              onClick={() => handleOpenActionDialog(branch)}
            />

            { branch.action?.name === 'En attente' && branch.done !== true && (
              <>
                <Typography variant="body2" color="textSecondary" ml={2} mb={2}>{branch.callback}h sont imposées avant de pouvoir poursuivre les actions de cette tâche.</Typography>
                <Typography variant="body2" color="textSecondary" ml={2} mb={2}>Vous recevrez une notification.</Typography>
              </>
            )}

            { identity?.role?.grade >= getRole('sdr') && branch.action?.name === 'En attente' && branch.callback !== null && (
              <Button
                label="Passer l'attente"
                startIcon={<FastForwardIcon sx={{ fontSize: '1.5rem', color: 'lightblue' }} />}
                variant="contained"
                color="warning"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => {
                  setActionToEdit(branch)
                  handleSubmit({
                    lead_id: props.leadRecord.id,
                    status: 1,
                    comment: 'Attente tâche passée par le responsable',
                    branchToEdit: branch.id,
                    skipWaitingTime: true
                  })
                }}
              />
            )}
          </div>
        )
      }) : <Loading /> }

      { identity?.role?.grade >= getRole('responsable') && (
        <Button
          label="Réinitialiser la tâche"
          variant="outlined"
          color="error"
          fullWidth
          onClick={() => resetTask()}
        />
      )}
    </>
  )
}

export default TaskActionsButtons