import { useState, useEffect } from 'react'
import { Typography, Stack, Button, Card, CardContent, CardMedia, List, ListItem, ListItemText } from '@mui/material'

import Avatar from '@mui/material/Avatar'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import TaskIcon from '@mui/icons-material/Task'
import { useGetIdentity } from "react-admin"
import { supabaseClient } from "../../supabase"

const DashboardPubeurTasks = ({ isMobile }) => {
  const { identity } = useGetIdentity()
  const [pubeurTasks, setPubeurTasks] = useState([])

  useEffect(() => {
    const getAllTasksPubeur = async () => {
      console.log('identity :', identity?.id);
      
      const { data, error } = await supabaseClient
        .from('lead_tasks')
        .select('progress, leads (assigned_pubeur, current_year_id), tasks (name)')
        .eq('leads.assigned_pubeur', identity?.id)
        .eq('leads.current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
  
      if (error) {
        console.error(error)
        return
      }

      const taskSummary = data.reduce((acc, item) => {
        if ( ! item?.tasks || ! item?.leads ) return acc
        
        const { name } = item?.tasks
        const progress = item?.progress
        
        // Initialize the task entry in the accumulator if it doesn't exist
        if ( ! acc[name] ) {
          acc[name] = {
            name,
            completed: 0,
            waiting: 0,
            not_started: 0
          }
        }
        
        // Determine the status of the task and increment the appropriate counter
        if ( progress === '100' ) {
          acc[name].completed++
        } else if ( progress > 0 && progress < 100 ) {
          acc[name].waiting++
        } else {
          acc[name].not_started++
        }
      
        return acc
      }, {})

      setPubeurTasks(Object.values(taskSummary))
    }

    getAllTasksPubeur()
  }, [])

  return (
    <Card sx={{ width: isMobile ? '100%' : '33%', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px' }}>
      { !isMobile && (
        <CardMedia
          component="img"
          alt="tâches attribuées"
          height="140"
          image="https://merriam-webster.com/assets/mw/images/article/art-wap-article-main/can-task-be-a-verb-5813-7075c71d8cf734c3c83e9edf76bc66fb@1x.jpg"
          sx={{ opacity: .75 }}
        />
      )}

      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tâches attribuées
        </Typography>

        <List dense={true}>
          { pubeurTasks && pubeurTasks.length > 0 ? (
            pubeurTasks.map((task, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: '#91bcba' }}>
                    <TaskIcon />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText primary={task.name} secondary={
                  [
                    task.completed > 0 ? `Complétées : ${task.completed}` : '',
                    task.waiting > 0 ? `En cours : ${task.waiting}` : '',
                    task.not_started > 0 ? `En attente : ${task.not_started}` : ''
                  ].filter(Boolean).join(', ')
                } />
              </ListItem>
            ))
          ) : ( <ListItem>
              <ListItemText primary="Aucune tâche attribuée" />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

export default DashboardPubeurTasks