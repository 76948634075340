import { useGetOne, useCreate, SimpleForm, TextInput, useNotify, SaveButton } from 'react-admin'
import { Box, Typography } from '@mui/material'

import { CardWithIcon } from '../../Design/Cards'

import EuroIcon from '@mui/icons-material/Euro'

const SettingSmic = ({ value }) => {
  const notify = useNotify()
  const [create, { isPending, error }] = useCreate()
  const { data: smicHoraire } = useGetOne('current_smic', { created_at: 'desc' })

  const handleSubmit = (value) => {
    create('current_smic', {
      data: { amount: value.amount }
    })
    notify('Montant du SMIC horaire mis à jour', { type: 'success' })
  }
  
  return (
    <div>
      <CardWithIcon
        to="/leads"
        icon={EuroIcon}
        title="Montant du SMIC horaire"
        subtitle={value}
        color="#ffbe4f"
      >
        <Box p={4}>
          <Typography variant="h4" gutterBottom>
            {smicHoraire?.amount} €
          </Typography>

          <Typography variant="body1" gutterBottom>
            En mettant à jour le montant du SMIC horaire, celui-ci sera automatiquement appliqué aux <i>futures</i> lignes de paie.
          </Typography>

          <SimpleForm
            onSubmit={handleSubmit}
            defaultValues={{ amount: '' }}
            toolbar={false}
          >
            <TextInput source="amount" label="Nouveau montant horaire BRUT" variant="outlined" />
            <SaveButton />
          </SimpleForm>
        </Box>
      </CardWithIcon>
    </div>
  )
}

export default SettingSmic