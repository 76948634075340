import { useMediaQuery } from '@mui/material'
import { EditDialog } from '@react-admin/ra-form-layout'

import LeadRDV from './LeadRDV'
import LeadForm from './LeadForm'
import LeadTasks from './leadsTasks'
import LeadParcours from './LeadParcours'
import LeadComments from './LeadComments'
import { LeadFavourite } from './LeadFavourite'
import { getItemFromLocalStorage } from '../common/LocalStorage'
import { CustomLeadsTab, CustomLeadTabbedShowLayout } from '../Design/CustomTabs'

const LeadEdit = () => {
  const localCurrentYear = getItemFromLocalStorage('localCurrentYear') 
  const currentYearId = localCurrentYear?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )
  
  return (
    <EditDialog
      title={<LeadFavourite />}
      fullWidth
      maxWidth="md"
      mutationMode="optimistic"
      sx={{
        '& .MuiDialogTitle-root': {
          backgroundColor: '#d7eae8',
        },
      }}
    >
      <CustomLeadTabbedShowLayout sx={{ minHeight: '90vh' }}>
        <CustomLeadsTab label="Fiche contact">
          <LeadForm action="edit" />
        </CustomLeadsTab>

        { !isMobile && (
          <CustomLeadsTab label="Parcours" path="history">
            <LeadParcours />
          </CustomLeadsTab>
        )}

        { !isMobile && currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID && (
          <CustomLeadsTab label="Commentaires" path="comments">
            <LeadComments />
          </CustomLeadsTab>
        )}

        { !isMobile && currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID && (
          <CustomLeadsTab label="Tâches" path="tasks">
            <LeadTasks />
          </CustomLeadsTab>
        )}

        { !isMobile && currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID && (
          <CustomLeadsTab label="RDV" path="rdv">
            <LeadRDV />
          </CustomLeadsTab>
        )}
      </CustomLeadTabbedShowLayout>
    </EditDialog>
  )
}

export default LeadEdit
