import * as React from "react"
import { useState, useEffect } from "react"
import {
  useEditContext,
  useGetIdentity,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  ChipField,
  ReferenceField,
  DeleteWithConfirmButton,
  useRedirect,
  useRecordContext,
  BooleanField,
  Loading,
  useRefresh
} from "react-admin"
import {
  Box,
  Stack,
  FormHelperText,
  Typography,
  Grid,
} from '@mui/material'
import { ReferenceManyToManyField } from '@react-admin/ra-relationships'

import { getRole } from '../common/roles'
import { supabaseClient } from '../supabase'
import { TextFieldCopy } from '../Design/CustomTextField'
import { getStatusNameById } from '../inboundContacts/inboundcontactStatus'

import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const LeadEventDelete = ({ record }) => {
  const localCurrentYear = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID

  return (
    <DeleteWithConfirmButton
      label="Supprimer"
      confirmTitle="Supprimer l'événement ?"
      confirmContent="Êtes-vous sûr de vouloir supprimer cet événement ? Cette action est irréversible."
      redirect={false}
      translateOptions={{ id: record.id }}
      disabled={localCurrentYear === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
    />
  )
}

const LeadStatusExpand = props => {
  const [eventQualificationsInfo, setEventQualificationsInfo] = useState(null)

  const getEventQualificationsInfo = async () => {
    const { data, error } = await supabaseClient
      .from('lead_events')
      .select('qualifications')
      .eq('id', props?.record?.id)

    if (error) {
      console.error(error)
      return
    }
    
    if ( !data.length ) return

    setEventQualificationsInfo(data[0].qualifications)
  }

  useEffect(() => {
    getEventQualificationsInfo()
  }, [])

  return (
    <div style={{ width: '10vw', padding: '.5rem', backgroundColor: eventQualificationsInfo ? '#eaeaea' : '', borderRadius: '5px' }}>
      {eventQualificationsInfo && Object.values(eventQualificationsInfo).map((qualification, index) => (
          <Grid container spacing={1} key={index}>
            <Grid item xs={10}>
              <Typography variant="body2" color="textSecondary">
                { qualification.name }
              </Typography>
            </Grid>
            
            <Grid item xs={2}>
              <CheckCircleOutlineIcon sx={{ fontSize: '1rem', mt: .2, color: 'darkgreen' }} />
            </Grid>
          </Grid>
        )
      )}
    </div>
  )
}

const LeadListActions = ({ leadRecord,...props}) => {
  const { record } = useEditContext(props)
  const [icActions, setICActions] = useState()

  useEffect(() => {
    if ( leadRecord.lead_source_id !== process.env.REACT_APP_LEAD_SOURCE_IMPORT_BROCHURES ) return

    const getICActions = async () => {
      const { data, error } = await supabaseClient
        .from('inbound_contact_logs')
        .select()
        .eq('lead_event_id', record?.id)

      if (error) {
        console.error(error)
        return
      }
      
      if ( !data.length ) return

      setICActions(data)
    }

    getICActions()
  }, [record])
  
  return icActions ? (
    <>
      { icActions.map((record, index) => {
        const recordAction = JSON.parse(record.action)

        return (
          <Stack direction="row" justifyContent="space-between" spacing={1} style={{ width: '75%', padding: '1rem', backgroundColor: '#eaeaea', borderRadius: '5px' }}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }} color="textSecondary">
              { index === 0 && 'Actions effectuées' }
            </Typography>
            
            <p>{`
            ${getStatusNameById(recordAction?.originType)} >
            ${getStatusNameById(recordAction?.actionType)} >
            ${getStatusNameById(recordAction?.actionResult)}
            `}</p>
          </Stack>
        )
      }) }
    </>
  ) : (
    <Stack direction="row" justifyContent="space-between" spacing={1} style={{ width: '75%', padding: '1rem', backgroundColor: '#eaeaea', borderRadius: '5px' }}>
      <i>Aucune action effectuée</i>
    </Stack>
  )
}

const LeadsExpand = props => {
  const localCurrentYear = JSON.parse(localStorage.getItem('localCurrentYear'))?.id || process.env.REACT_APP_CURRENT_YEAR_ID

  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <LeadStatusExpand {...props} />

      <LeadListActions {...props} />

      { props.identity?.role?.grade >= getRole('responsable') && (
        <LeadEventDelete {...props} /> 
      )}
    </Stack>
  )
}

const LeadParcours = props => {
  const { record } = useEditContext(props)
  const { identity } = useGetIdentity()
  const redirect = useRedirect()
  const refresh = useRefresh()

  useEffect(() => {
    if ( !record ) return
    
    refresh()
  }, [record])

  /* to redirect to the event when clicking the name */
  const EventTextField = ({ record, source, ...rest }) => (
    <TextField
      source={source}
      style={ identity?.role?.grade >= getRole('responsable') ? { cursor: 'pointer', textDecoration: 'underline', color: '#39a1c4' } : {} }
      {...rest}
      onClick={(e) => {
        if ( identity?.role?.grade < getRole('responsable') ) return

        e.stopPropagation()

        if (record) {
          redirect(`/events/${record.id}`)
        }
      }}
    />
  )
  
  return (
    <>
      <Box display="inline-flex" mb={2} backgroundColor="#d5eae9" style={{ borderRadius: '5px', padding: '5px 10px 8px 10px' }}>
        <NotListedLocationIcon sx={{ mt: 1.5, mr: 2, color: '#555' }} />

        <Box marginRight={4}>
          <FormHelperText>Provenance</FormHelperText>
          
          <ReferenceField
            label={false}
            source="lead_source_id"
            reference="lead_sources"
            emptyText="Aucune provenance"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Box>

        <Box>
          <FormHelperText>Année scolaire</FormHelperText>

          <ReferenceField
            label={false}
            source="current_year_id"
            reference="lead_years"
            emptyText="Aucune année"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Box>
      </Box>

      <ReferenceManyField
        label={false}
        reference="lead_events"
        target="lead_id"
        sort={{ field: 'created_at', order: 'DESC' }}
      >
        <Datagrid
          expand={<LeadsExpand leadRecord={record} identity={identity} />}
          bulkActionButtons={false}
          sx={{
            '& .RaDatagrid-expandIconCell': {
              backgroundColor: '#f5f5f5',
              p: 1,
            },
          }}
        >
          { identity?.role?.grade >= getRole('admin') && (
            <TextFieldCopy label="ID" source="id" />
          )}

          <DateField label="Date" source="created_at" locales="fr-FR" />

          <ReferenceField
            label="Événement"
            source="event_id"
            reference="events"
            link={false}
          >
            <FunctionField
              render={(record) => (
                <EventTextField record={record} source="name" />
              )}
            />
          </ReferenceField>

          <ReferenceField
            label="Pubeur"
            source="pubeur_id"
            reference="profiles"
            link={false}
          >
            <FunctionField
              render={record => `${record.first_name} ${record.last_name}`}
            />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}

export default LeadParcours