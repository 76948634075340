import React, { useEffect, useState, useRef } from 'react'
import {
  email,
  required,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  Confirm,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  TextInput,
  ReferenceField,
  FunctionField,
  useGetIdentity,
  Button,
} from 'react-admin'
import {
  Grid,
  Chip,
  Typography,
  Autocomplete,
  TextField as MuiTextField,
  Tooltip,
  Stack,
  useMediaQuery,
} from '@mui/material'

import { SimpleForm } from '@react-admin/ra-rbac'

import { getRole } from '../common/roles'
import { FormCard } from '../Design/Cards'
import { supabaseClient } from '../supabase'
import toTimeZone from '../common/toTimeZone'
import fetchEvents from '../common/fetchEvents'
import UserEditToolbar from './userEditToolbar'
import lighterColor from '../common/lighterColor'
import { availableOptions } from '../common/options'
import LeadUnicityAction from './LeadUnicityAction'
import addNewComment from '../common/addLeadComment'
import { TextInputCopy } from '../Design/CustomTextInput'
import { classes as optionClasses } from '../common/classes'
import { filterBlankSpaces } from '../common/customTextFilter'
import { checkForDuplicates } from './LeadUtils'
import { specialities as optionSpecialities } from '../common/specialities'
import { getValidEmail, getValidPhone, toCamelCase, toCapitalCase } from '../common/Formating'
import { AssociatedParentsList, ParentAssignationDialogButton } from './LeadParentAssignation'
import { validateAtLeastOneContactInfo, validateAtLeastOneName, validatePhone, validateCenter } from '../common/Validate'

import InfoIcon from '@mui/icons-material/Info'
import CircleIcon from '@mui/icons-material/Circle'
import PeopleIcon from '@mui/icons-material/People'
import NearbyErrorIcon from '@mui/icons-material/NearbyError'
import CircularProgress from '@mui/material/CircularProgress'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../common/LocalStorage'

const LeadForm = ({ action , ...props }) => {
  const record = useRecordContext(props)
  if ( record ) { record.comment = '' }
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const isAdmin = identity?.role?.grade === getRole('admin')
  const isPubeur = identity?.role?.grade < getRole('responsable')
  const isMobile = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    { noSsr: true }
  )
  const currentTimestamp = isPubeur ? new Date().toISOString() : null
  
  const localCenter = isAdmin
    ? { id: '0000', name: 'Tous les centres' }
    : identity?.center
  const localCurrentYear = getItemFromLocalStorage('localCurrentYear') 
  const currentYearId = localCurrentYear?.id || process.env.REACT_APP_CURRENT_YEAR_ID
  const currentYearName = localCurrentYear?.name || '2024/2025'

  const adminClasses = [
    ...optionClasses[process.env.REACT_APP_CENTER_MYPREPA]?.options
  ]

  const [formDataState, setFormDataState] = useState({})
  const [prevFormDataState, setPrevFormDataState] = useState({ email: '', phone: '', center_id: '' })
  const [matchingContact, setMatchingContact] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, data: null })
  const [eventQualifications, setEventQualifications] = useState([])
  const [tagsChoices, setTagsChoices] = useState(null)
  const [selectedTags, setSelectedTags] = useState([])
  const [loadingSaveTags, setLoadingSaveTags] = useState(false)

  const centerTags = getItemFromLocalStorage('centerTags') || null
  const eventsInfo = getItemFromLocalStorage('eventsInfo') || null

  // tag management
  const saveCurrentTags = async (tagsIds, stopRGPD, isInterested, isNotInterested) => {
    setLoadingSaveTags(true)

    const { error } = await supabaseClient
      .from('leads')
      .update({
        tags_ids: tagsIds,
        stop_rgpd: stopRGPD,
        interested: isInterested,
        not_interested: isNotInterested,
      })
      .eq('id', record.id)

    if ( error ) {
      console.error('Erreur lors de la mise à jour des tags')
    }

    record.tags_ids = tagsIds
    refresh()

    setTimeout(() => {
      setLoadingSaveTags(false)
    }, 1000)
  }

  const handleTagChange = (event, newValue) => {
    const lowerCaseTags = newValue.map(tag => tag.name.toLowerCase())

    const stopRGPDSelected = lowerCaseTags.some(tag => tag.includes('stop rgpd'))
    let interestedSelected = lowerCaseTags.some(tag => tag.includes('intéressé(e) par la prépa'))
    let notInterestedSelected = lowerCaseTags.some(tag => tag.includes('pas intéressé(e) par la prépa'))
  
    // If both "Intéressé(e) par la prépa" and "Pas intéressé(e) par la prépa" are selected,
    // keep only the last selected one and unselect the other
    if (interestedSelected && notInterestedSelected) {
      const lastSelectedTag = newValue[newValue.length - 1]
      const unselectedTagName =
        lastSelectedTag.name.toLowerCase() === 'intéressé(e) par la prépa'
          ? 'pas intéressé(e) par la prépa'
          : 'intéressé(e) par la prépa';
      newValue = newValue.filter(tag => tag.name.toLowerCase() !== unselectedTagName)

      if (unselectedTagName === 'intéressé(e) par la prépa') {
        interestedSelected = false
        notInterestedSelected = true
      } else {
        interestedSelected = true
        notInterestedSelected = false
      }
    }
  
    // Filter selected tags to match the structure of options
    const filteredSelectedTags = newValue.filter(tag =>
      tagsChoices.some(option => option.id === tag.id)
    )
  
    setSelectedTags(filteredSelectedTags)

    const newTagsIds = newValue.map(tag => tag.id)

    saveCurrentTags(newTagsIds, stopRGPDSelected, interestedSelected, notInterestedSelected)
  }

  const getTagsChoices = async () => {
    let tagsData = centerTags?.tags || []

    if ( isAdmin || ( tagsChoices?.length === 0 && ! centerTags ) ) {
      console.log('Getting tags choices for the center...')

      let query = supabaseClient
        .from('tags')
        .select('id, name, center: center_id (name), color')
        .eq('center_id', record.center_id)
      
      const { data, error } = await query

      if ( error ) {
        console.error('Erreur lors de la récupération des qualifications')
      }

      if ( ! data ) return

      const tagChoices = data.map(tag => ({
        id: tag.id,
        name: tag.name + ( isAdmin ? ` (${tag.center.name})` : '' ),
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
    
      setTagsChoices(tagChoices)
      setItemToLocalStorage('centerTags', { tags: data, updatedAt: Date.now() })

      tagsData = data
    } else {
      setTagsChoices(tagsData)
    }

    if ( record?.tags_ids ) {
      console.log('Setting selected tags in the lead...');
      
      const selectedTag = tagsData?.filter(tag => record.tags_ids.includes(tag.id)).map(tag => ({
        id: tag.id,
        name: tag.name,
        color: tag.color ?? '999',
        colorLight: lighterColor(tag.color) ?? 'eee'
      }))
  
      setSelectedTags(selectedTag)
    }
  }

  // events and their qualifications
  const handleEventChange = (eventId) => {
    if ( ! eventId ) {
      setEventQualifications([])
      return
    }

    // find event qualification in the eventsInfo list
    const thisEvent = eventsInfo.events?.find(event => event.id === eventId)
    const flattenedEventQualifications = thisEvent?.qualificationInfo?.flat()

    setEventQualifications(flattenedEventQualifications)
    setIsValidSave(false) // saving has to be manual when event changes (for now)
  }

  const getEvents = async () => {
    // fetch events
    const events = await fetchEvents(formDataState?.center_id || localCenter?.id, identity, currentTimestamp)
    
    // fetch qualifications
    const { data, error } = await supabaseClient
      .from('types_qualifications')
      .select('id, name')

    if (error) {
      console.error('Error getting qualifications for one event: ', error)
    }

    for ( const event of events ) {
      // to display in LeadForm
      if ( event.date ) {
        const splitedDate = event.date.split('T')
        event.startDate = splitedDate[0] || null
      }

      // change startDate to dd-mm-yyyy
      if (event.startDate) {
        const date = event.startDate.split('-')
        event.startDate = `${date[2]}/${date[1]}/${date[0]}`
      }

      if ( ! event?.qualifications_ids ) continue

      let qualificationInfo = []
      for ( const qualificationId of event.qualifications_ids ) {
        const qual = data.filter(qualification => qualification.id === qualificationId)

        qualificationInfo.push(qual)
      }
      
      event.qualificationInfo = qualificationInfo ?? []
    }

    // reorder events by date DESC
    events.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    
    setItemToLocalStorage('eventsInfo', { events, updatedAt: Date.now() })
    setEvents(events)
  }

  // save form
  const handleDialogClose = () => setConfirmDialog({ open: false })
  
  const handleConfirm = () => {
    setConfirmDialog({ open: false })
    saveLeadAndEvent(confirmDialog.data)
  }

  const transformData = (data) => {
    const {
      id,
      assigned_pubeur,
      email,
      phone,
      hot_list_bool,
      first_name,
      last_name,
      lead_source_id,
      created_by,
      fts,
      ...rest
    } = data
  
    return {
      ...rest,
      id,
      phone: getValidPhone(phone),
      email: getValidEmail(email),
      assigned_pubeur: id
        ? ( hot_list_bool
              ? identity?.id
              : assigned_pubeur )
        : ( identity?.role?.grade < 50 ? identity?.id : null ),
      first_name: toCamelCase(first_name),
      last_name: toCapitalCase(last_name),
      lead_source_id: lead_source_id ?? process.env.REACT_APP_LEAD_SOURCE_WEB,
      current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
      created_by: record ? created_by : identity?.id,
    }
  }

  const handleChangeYear = async (leadId) => {
    // update current_year_id in leads
    const { error } = await supabaseClient
      .from('leads')
      .update({ current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID })
      .eq('id', leadId)

    if ( error ) {
      console.error('Error updating current_year_id in leads')
    }

    notify('Contact passé à l\'année en cours', { type: 'success' })
    refresh()
  }

  const handleSave = async values => {
    setIsDisabled(true)

    const { id, event, event_qualifications } = values
    delete values.event_qualifications
    
    const cleanLeadData = transformData(values)

    if ( ! id && ! event) {
      setConfirmDialog({ open: true, data: cleanLeadData })
      return
    }
  
    saveLeadAndEvent(cleanLeadData, event_qualifications)
  }

  const saveLeadAndEvent = async (cleanLeadData, event_qualifications) => {
    const { event, id, ...lead } = cleanLeadData

    if ( id ) {
      lead.updated_at = toTimeZone(new Date(), 'Europe/Paris') ?? null
      lead.updated_by = identity?.id ?? null
    }

    if ( ! id && ! lead.lead_source_id ) {
      lead.lead_source_id = process.env.REACT_APP_DEFAULT_LEAD_SOURCE_ID
    }

    try {
      let response

      if ( ! id ) {
        response = await dataProvider.create('leads', { data: lead })

        // also add the the list of created contacts
        if ( response && response.data ) {
          const { error } = await supabaseClient
            .rpc('rpc_manage_paie_pubeur_contacts', {
              _profileid: identity?.id,
            })

          if ( error ) {
            console.error('Error using rpc_manage_paie_pubeur_contacts :', error)
          }
        }
      } else {
        response = await dataProvider.update(
          'leads',
          { id: id, data: lead, previousData: record },
        )
      }

      if ( ! response || ! response.data || ! event ) {
        if ( lead.comment ) addNewComment(lead.id ?? response.data.id, identity?.id, null, lead.comment)

        if ( record && ( lead.assigned_pubeur !== record?.assigned_pubeur ) ) {
          addNewComment(lead.id ?? response.data.id, identity?.id, null, `*AUTO* Contact récupéré par ${identity?.fullName}`)
        }

        refresh()
        notify(`Contact ${action === 'create' ? 'ajouté' : 'modifié'}`,  { 'type' : 'success' })
        
        if ( action === 'create' ) redirect('list', 'leads')
        
        return
      }

      // check if event is already in the parcours
      const { data: checkLeadEvent, error: errorCheckLeadEvent } = await supabaseClient
        .from('lead_events')
        .select('qualifications')
        .eq('lead_id', response.data.id)
        .eq('event_id', event)
        .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)
      
      if ( errorCheckLeadEvent ) {
        console.error('Error checking event already in lead_events for lead')
      }

      // get new qualifications
      let qualificationInfo = []
      if ( event_qualifications ) {
        // find the qualifications selected in eventQualifications, then add them to qualificationInfo
        for ( const qual of event_qualifications ) {
          const foundQual = eventQualifications.find(eventQual => eventQual.id === qual)
          if ( foundQual ) qualificationInfo.push(foundQual)
        }
      }

      if ( checkLeadEvent?.length > 0 ) {
        // update qualification(s) of lead event
        if ( qualificationInfo.length > 0 ) {
          let existingQualifications = checkLeadEvent[0].qualifications || {}
  
          for (const qual of qualificationInfo) {
            existingQualifications = {
              ...existingQualifications,
              [qual.name]: qual
            }
          }

          const { error: errorUpdateLeadParcours } = await supabaseClient
            .from('lead_events')
            .update({ qualifications: existingQualifications })
            .eq('lead_id', response.data.id)
            .eq('event_id', event)
            .eq('current_year_id', process.env.REACT_APP_CURRENT_YEAR_ID)

          if ( errorUpdateLeadParcours ) {
            console.error('Error updating qualifications in lead_events')
          }
        }

        // add comment(s) to lead
        await addNewComment(lead.id ?? response.data.id, identity?.id, null, lead.comment)

        if ( record && ( lead.assigned_pubeur !== record?.assigned_pubeur ) ) {
          await addNewComment(lead.id ?? response.data.id, identity?.id, null, `*AUTO* Contact assigné à ${identity?.fullName}`)
        }

        refresh()
        notify(`Contact ${action === 'create' ? 'ajouté' : 'modifié'}`,  { 'type' : 'success' })
        if ( action === 'create' ) redirect('list', 'leads')
        
        return
      }

      // create new lead event with the qualifications
      let newQualifications = {}
      for (const qual of qualificationInfo) {
        newQualifications = {
          ...newQualifications,
            [qual.name]: {
              id: qual.id,
              name: qual.name
            }
        }
      }

      const leadEventResponse = await dataProvider.create(
        'lead_events',
        {
          data: {
            lead_id: response.data.id,
            event_id: event,
            pubeur_id: identity?.id ?? null,
            comment: null,
            current_year_id: process.env.REACT_APP_CURRENT_YEAR_ID,
            qualifications: qualificationInfo ? newQualifications : null,
          }
        }
      )

      if (! leadEventResponse || ! leadEventResponse.data) {
        console.error('Failed to create lead event')
      }

      await addNewComment(lead.id ?? response.data.id, identity?.id, null, lead.comment)

      if ( record && ( lead.assigned_pubeur !== record?.assigned_pubeur ) ) {
        await addNewComment(lead.id ?? response.data.id, identity?.id, null, `*AUTO* Contact assigné à ${identity?.fullName}`)
      }
  
      refresh()
      notify(`Contact ${action === 'create' ? 'ajouté' : 'modifié'}`,  { 'type' : 'success' })

      if ( action === 'create' ) {
        redirect('list', 'leads')
      }
  
      return
    } catch (error) {
      console.error('An error occurred:', error)
      notify('Une erreur est survenue', { type: 'error' })
    }
  }

  const [isValidSave, setIsValidSave] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isDisabled, setIsDisabled] = useState(currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true)

  let debounceTimer = useRef(null) // to wait for typing to finish before saving
  const autosaveInput = async (value, source) => {
    if ( ! record ) return

    const updateInput = () => {
      // in case of jsonb field
      if ( source === 'streetAddress' ) {
        source = 'address'
        value = { street: value, postalCode: record.address?.postalCode, city: record.address?.city }
      } else if ( source === 'streetPostalcode' ) {
        source = 'address'
        value = { postalCode: value, city: record.address?.city, street: record.address?.street }
      } else if ( source === 'streetCity' ) {
        source = 'address'
        value = { city: value, postalCode: record.address?.postalCode, street: record.address?.street }
      }

      const updatedLead = {
        ...record,
        [source]: value,
        updated_at: toTimeZone(new Date(), 'Europe/Paris') || record?.updated_at,
      }

      if ( isValidSave ) {
        setTimeout(async () => {
          setIsSaving(true)
          setIsValidSave(false) // prevent multi saving

          try {
            const response = await dataProvider.update(
              'leads',
              { id: record.id, data: updatedLead, previousData: record }
            )

            if ( ! response || ! response.data ) {
              setIsSaving(false)
              return
            }
          } catch (error) {
            console.error('Error occurred in autosave:', error)
            notify('Une erreur est survenue lors de la mise à jour automatique du contact avec '+ source, { type: 'error' })
          } finally {
            refresh()
            setIsSaving(false)
            setIsValidSave(true)
          }
        }, 800)
      }
    }

    if ( debounceTimer.current ) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      updateInput()
    }, 800)
  }
  
  const [events, setEvents] = useState([])

  useEffect(() => {
    if ( ! record ) return

    getTagsChoices()
  }, [record])

  useEffect(() => {
    if ( events?.length === 0 && ( isAdmin || ! eventsInfo ) ) {
      getEvents()
    } else {
      setEvents(eventsInfo.events)
    }
  }, [])

  useEffect(() => {
    const newEmail = getValidEmail(formDataState?.email)
    const newPhone = getValidPhone(formDataState?.phone)
    const newCenterId = formDataState?.center_id ?? null

    if ( ( ! newEmail || ! newPhone ) && ! newCenterId ) return
    
    const updatedField = newPhone !== prevFormDataState.phone ? 'phone' : 'email'

    const timeoutId = setTimeout(() => {
      checkForDuplicates(formDataState.id, newPhone, newEmail, newCenterId).then(matching => {
        if (matching) {
          setMatchingContact({ ...matching, matchingField: updatedField })
        } else {
          setMatchingContact(null)
          if ( formDataState?.email !== record?.email ) autosaveInput(newEmail, 'email')
          // if ( formDataState?.phone !== record?.phone ) autosaveInput(newPhone, 'phone') // for security
          if ( formDataState?.center_id !== record?.center_id ) autosaveInput(newCenterId, 'center_id')
        }
      })
    }, 800)

    setPrevFormDataState({ email: newEmail, phone: newPhone, center_id: newCenterId })
    return () => clearTimeout(timeoutId)
  }, [formDataState?.email, formDataState?.phone, formDataState?.center_id])

  return (
    <>
      <LeadUnicityAction mode={action} contact={matchingContact} style={{marginTop: '25px'}} />

      <SimpleForm
        toolbar={ !isMobile ? <UserEditToolbar isDisabled={isDisabled} autosave={isSaving} gradeRole={identity?.role?.grade} {...props} /> : undefined }
        onSubmit={handleSave}
        defaultValues={{
          phone: null,
          email: null,
          last_name: null,
          first_name: null,
          options: [],
          desired_options: [],
          class_id: null,
          school_id: null,
          assigned_pubeur: null,
          address: {
            address: null,
            street: null,
            postalCode: null,
            city: null,
          },
          center_id: (isAdmin ? null : localCenter?.id),
          comment: null,
        }}
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                { currentYearId !== process.env.REACT_APP_CURRENT_YEAR_ID && (
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body1" style={{ color: '#db9a51' }} mb={1}><i>Contact année scolaire {currentYearName} pour consultation uniquement</i></Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      { ! isPubeur &&
                        <Button
                          label="Passer à l'année en cours"
                          onClick={() => handleChangeYear(record.id)}
                          variant="contained"
                          color="warning"
                          fullWidth
                        />
                      }
                    </Grid>
                  </Grid>
                )}
 
                { currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID && (
                  <BooleanInput
                    label={
                      <>
                        {formData?.assigned_pubeur ?
                          <ReferenceField
                            label="Pubeur"
                            source="assigned_pubeur"
                            reference="profiles"
                            link={false}
                          >
                            <FunctionField
                              render={record => {
                                if ( ( formData.hot_list_bool && record.id === identity?.id ) || record.id === identity?.id ) {
                                  return `Ce prospect m'est assigné (${identity?.fullName})`
                                }

                                return `Récupérer (actuellement assigné à ${record.first_name} ${record.last_name})`
                              }}
                            />
                          </ReferenceField>
                        :
                          `Ajouter à ma liste de prospects (${identity?.fullName} - moi)`
                        }
                      </>
                    }
                    source="hot_list_bool"
                    readOnly={ formData?.id ? formData.assigned_pubeur === identity?.id : true }
                    defaultValue={
                      typeof formData.hot_list_bool !== 'undefined'
                      ? ( formData.hot_list_bool ?
                            ( formData.assigned_pubeur === identity?.id
                              ? formData.hot_list_bool = true
                              : formData.hot_list_bool = false )
                          : ( identity?.role?.grade < getRole('coordinateur')
                            ? true
                            : false ) ) 
                      : ( formData.assigned_pubeur === identity?.id
                          ? formData.hot_list_bool = true
                          : formData.hot_list_bool = false )
                    }
                    sx={{ mt: 2 }}
                  />
                )}

                { record?.id && identity?.role?.grade >= getRole('responsable') && (
                  <TextInputCopy label="ID" source="id" fullWidth readOnly />
                )}

                { record && record?.id && identity?.role?.grade >= getRole('responsable') && ! record.lead_source_id &&
                  currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID && (
                  <FormCard cardWidth="100%" title="Origine du contact">
                    <Typography variant="body1" mt={3} mb={1}>
                      <NearbyErrorIcon
                        sx={{ marginRight: '20px', color: 'darkred' }}
                      />
                      Ce contact n'a pas d'origine. Si vous la connaissez, veuillez l'indiquer.
                      Vous pouvez vous aider du Parcours pour en savoir plus.
                    </Typography>

                    <ReferenceInput
                      source="lead_source_id"
                      reference="lead_sources"
                      sort={{ field: 'name', order: 'ASC' }}
                    >
                      <AutocompleteInput
                        label="Choisir une origine"
                        variant="outlined"
                        filterToQuery={q => ({ 'name@ilike': q })}
                      />
                    </ReferenceInput>
                  </FormCard>
                )}

                <Stack spacing={2} mb={2} direction={isMobile ? 'column' : 'row' } alignItems="top" justifyContent="space-between">
                  <FormCard cardWidth="55%" title="Informations contact">
                    { isAdmin && (
                      <ReferenceInput source="center_id" reference="centers">
                        <AutocompleteInput
                          label="Centre"
                          optionText="name"
                          variant="outlined"
                          filterToQuery={searchText => filterBlankSpaces(searchText)}
                          options={{ type: 'search' }}
                          validate={validateCenter}
                          readOnly={currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true}
                          onChange={(value, event) => setFormDataState({ ...formData, center_id: value })}
                          fullWidth
                        />
                      </ReferenceInput>
                    )}

                    <Grid container spacing={1}>
                      <Grid item xs={12} md={5}>
                        { !isAdmin && record?.stop_rgpd ? <p><b>Ce contact ne souhaite plus être recontacté</b></p> : <TextInput
                          label="Téléphone"
                          source="phone"
                          variant="outlined"
                          onChange={e => setFormDataState({ ...formData, phone: e.target.value }) }
                          validate={[validatePhone, validateAtLeastOneContactInfo]}
                          fullWidth
                          readOnly={ currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                        /> }
                      </Grid>
                      
                      <Grid item xs={12} md={7}>
                        <TextInput
                          label="Email"
                          source="email"
                          variant="outlined"
                          validate={[email(), validateAtLeastOneContactInfo]}
                          onChange={e => setFormDataState({ ...formData, email: e.target.value }) }
                          fullWidth
                          readOnly={ currentYearId === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextInput
                          label="Nom"
                          source="last_name"
                          variant="outlined"
                          fullWidth
                          validate={validateAtLeastOneName} readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(e) => autosaveInput(e.target.value ?? null, 'last_name')}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextInput
                          label="Prénom"
                          source="first_name"
                          variant="outlined"
                          fullWidth
                          validate={validateAtLeastOneName} readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(e) => autosaveInput(e.target.value ?? null, 'first_name')}
                        />
                      </Grid>
                    </Grid>
                    
                    <ReferenceInput
                      source="school_id"
                      reference="schools"
                    >
                      <AutocompleteInput
                        label="Lycée"
                        optionText="name"
                        variant="outlined"
                        filterToQuery={searchText => ({ 'name@ilike': searchText })}
                        options={{ type: 'search' }}
                        fullWidth
                        readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                        onChange={(event, value) => autosaveInput(value?.id ?? null, 'school_id')}
                      />
                    </ReferenceInput>

                    <Grid container spacing={1}>
                      <Grid item xs={12} md={5}>
                        <TextInput
                          label="Adresse"
                          source="address.street"
                          variant="outlined"
                          fullWidth
                          readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(e) => autosaveInput(e.target.value ?? null, 'streetAddress')}
                        />
                      </Grid>

                      <Grid item xs={4} md={3}>
                        <TextInput
                          label="Code postal"
                          source="address.postalCode"
                          variant="outlined"
                          fullWidth
                          readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(e) => autosaveInput(e.target.value ?? null, 'streetPostalcode')}
                        />
                      </Grid>

                      <Grid item xs={8} md={4}>
                        <TextInput
                          label="Ville"
                          source="address.city"
                          variant="outlined"
                          fullWidth
                          readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(e) => autosaveInput(e.target.value ?? null, 'streetCity')}
                        />
                      </Grid>
                    </Grid>
                  </FormCard>

                  <FormCard cardWidth="45%" title="Évènement en cours">
                    { currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID && (
                      <>
                        <AutocompleteInput
                          label="Choisir un événement"
                          source="event"
                          variant="outlined"
                          choices={events?.map(event => ({ id: event.id, name: `${event.name} du ${event.startDate}` }))}
                          isOptionEqualToValue={(option, value) => option.label === value.name}
                          filterToQuery={q => ({ 'name@ilike': q })}
                          validate={formData.id ? undefined : required()}
                          sort={{ field: 'name', order: 'ASC' }}
                          onChange={(value, event) => handleEventChange(value) }
                        />
                        
                        { formData?.event && eventQualifications?.length > 0 && (
                          <CheckboxGroupInput
                            source="event_qualifications"
                            label="Remplir / mettre à jour"
                            choices={eventQualifications}
                            mt={0}
                          />
                        )}
                      </>
                    )}
                    
                    { record?.id && (
                      <>
                        { identity?.role?.grade >= getRole('responsable') && (
                          <ReferenceInput
                            label="Qualification"
                            source="status_inscription_id"
                            reference="qualifications"
                            readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          >
                            <AutocompleteInput
                              label="Statut inscrit"
                              optionText="name"
                              variant="outlined"
                              filterToQuery={searchText => ({ 'name@ilike': searchText })}
                              options={{ type: 'search' }}
                              fullWidth
                              readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                              onChange={(value, event) => autosaveInput(event.id ?? null, 'status_inscription_id')}
                            />
                          </ReferenceInput>
                        )}

                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            { tagsChoices ?
                              tagsChoices.length > 0 && (
                                <Autocomplete
                                  multiple
                                  options={tagsChoices}
                                  getOptionLabel={option => option.name}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  filterSelectedOptions
                                  onChange={handleTagChange}
                                  sx={{ marginBottom: '1em' }}
                                  disabled={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                                  renderInput={params => (
                                    <MuiTextField
                                      {...params}
                                      variant="outlined"
                                      label="Sélectionner un ou plusieurs tags"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {loadingSaveTags ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={`li-tag-${option.id}`} sx={{ padding: '2px', backgroundColor: `#${option.color} !important` }}>
                                        <CircleIcon sx={{ fontSize: '1rem', marginRight: '10px', color: `#${option.color}` }} />
                                        {option.name}
                                      </li>
                                    );
                                  }}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        {...getTagProps({ index })}
                                        variant="outlined"
                                        label={option.name}
                                        key={`chip-tag-${option.id}`}
                                        sx={{
                                          color: `#${option.color} !important`,
                                          backgroundColor: `#${option.colorLight} !important`,
                                          fontWeight: 500,
                                          border: 'none',
                                          fontSize: '12px',
                                          margin: '1px',
                                          padding: '1px',
                                          height: '24px',
                                          '& .MuiChip-deleteIcon': {
                                            display: identity?.role?.grade < getRole('responsable') ?
                                              option.name === 'Stop RGPD' ? 'none' : 'inline-flex'
                                              : undefined,
                                          }
                                        }}
                                      />
                                    ))
                                  }
                                  value={selectedTags}
                                />
                              )
                              : <p><i>Chargement des tags...</i></p>
                            }
                          </Grid>

                          <Grid item xs={1}>
                            <Tooltip title="Les tags permettent d'ajouter une étiquette au contact. Ils sont automatiquement sauvegardés.">
                              <InfoIcon sx={{ color: '#a1d1ce', mt: 2 }} />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </FormCard>
                </Stack>

                { record?.id && (
                  <FormCard title="Informations parents">
                    <AssociatedParentsList record={record} />
                    { currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID && (
                      <ParentAssignationDialogButton record={record} />
                    )}
                  </FormCard>
                )}

                <FormCard title="Formation actuelle">
                  { currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID
                    ?
                      <>
                        <RadioButtonGroupInput
                          source="class_id"
                          label={ `Classe en ${currentYearName}` }
                          choices={
                            isAdmin
                              ? adminClasses
                              : optionClasses[localCenter?.id]?.options ?? optionClasses['default']?.options
                          }
                          readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                          onChange={(value, event) => autosaveInput(event ?? null, 'class_id')}
                        />

                        { formData.class_id === 0 &&
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <TextInput
                                label="Autre"
                                source="custom_class"
                                fullWidth
                                onChange={e => autosaveInput(e.target.value ?? null, 'custom_class')}
                              />
                            </Grid>
                          </Grid>
                        }
                      </>
                    : <Typography variant="body1" mt={3}><b>Classe</b> : {record.class_id ?? `Pas d'information`}</Typography>
                  }

                  { currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID
                    ?
                      <CheckboxGroupInput
                        source="options"
                        label="Spécialités"
                        choices={optionSpecialities}
                        fullWidth
                        readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                      />
                    : <Typography variant="body1" mt={3}><b>Spécialités</b> : {record.options ?? `Pas d'information`}</Typography>
                  }
                </FormCard>

                <FormCard title="Formation souhaitée l'année prochaine">
                  { currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID
                    ? 
                      <CheckboxGroupInput
                        source="desired_options"
                        label={ availableOptions[localCenter?.id]?.label ?? availableOptions['default'].label }
                        choices={ availableOptions[localCenter?.id]?.options ?? availableOptions['default'].options }
                        row={false}
                        readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                      />
                    : <Typography variant="body1" mt={3}><b>Spécialités souhaitées</b> : {record.desired_options ?? `Pas d'information`}</Typography>
                  }
                </FormCard>

                <FormCard title="Commentaire">
                  <TextInput
                    label={ record ? `Consultez tous les commentaires dans l'onglet en haut de la fiche` : null } type="Rich text" source="comment" multiline
                    readOnly={ currentYearId  === process.env.REACT_APP_CURRENT_YEAR_ID ? undefined : true }
                    fullWidth
                  />
                </FormCard>
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>

      <Confirm
        isOpen={confirmDialog.open}
        loading={false}
        title="Confirmation"
        content="Aucun événement n'a été sélectionné. Êtes-vous sûr de vouloir sauvegarder ?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default LeadForm 