const messageOldDatesRegex = /\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\s(\d{4}|)\sà\s\d{1,2}\s?:\s?\d{2}/giu
const messageNormalDateRefex = /\b[\wéèàùçÉÈÀÙÇ]{1,10}\.?\s\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\.?\sà\s\d{1,2}\s?:\s?\d{2}\b/giu
const messageTodayDatesRegex = /(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/giu
const messageYesterdayDatesRegex = /(Yesterday|Hier)\s\d{1,2}\s?:\s?\d{2}/giu
const messageOrImessage = /^(iMessage|Message)$/giu

const allDateRegex = [
  messageOldDatesRegex,
  messageNormalDateRefex,
  messageTodayDatesRegex,
  messageYesterdayDatesRegex
]

const padZero = (num) => num < 10 ? `0${num}` : `${num}`
const padMilliseconds = (num) => num < 10 ? `00${num}` : num < 100 ? `0${num}` : `${num}`

/**
 * Make sure to identify and separate the date from the regular message from the sender (due to processed image)
 * This function ensures the correct order of the messages before and after date splits.
 * 
 * @param {*} messagesSender - for the messages from the sender
 * @returns the messages separated by date and regular message in the correct order
 */
export const checkAndIdentifyDate = (messagesSender) => {
  const separatedMessages = []
  
  messagesSender.forEach((messageObj) => {
    let remainingText = String(messageObj.text).trim() // Ensure message is a string and trim it
    let lastYPosition = messageObj.y // Adjust the y-position to maintain visual flow

    // Loop until no text is left
    while (remainingText) {
      let dateMatch = null
      let matchIndex = -1 // Initialize match index

      // Try to match any valid date pattern in the remaining text
      if (remainingText.match(messageOldDatesRegex)) {
        dateMatch = remainingText.match(messageOldDatesRegex)[0]
        matchIndex = remainingText.indexOf(dateMatch)
      } else if (remainingText.match(messageNormalDateRefex)) {
        dateMatch = remainingText.match(messageNormalDateRefex)[0]
        matchIndex = remainingText.indexOf(dateMatch)
      } else if (remainingText.match(messageTodayDatesRegex)) {
        dateMatch = remainingText.match(messageTodayDatesRegex)[0]
        matchIndex = remainingText.indexOf(dateMatch)
      } else if (remainingText.match(messageYesterdayDatesRegex)) {
        dateMatch = remainingText.match(messageYesterdayDatesRegex)[0]
        matchIndex = remainingText.indexOf(dateMatch)
      }

      // If a date is found, split the message at the correct index
      if (dateMatch && matchIndex !== -1) {
        const before = remainingText.substring(0, matchIndex).trim()
        const after = remainingText.substring(matchIndex + dateMatch.length).trim()

        // If there's text before the date, add it as a separate message
        if (before) {
          separatedMessages.push({
            text: before,
            x: messageObj.x,
            y: lastYPosition,
            width: messageObj.width,
            height: messageObj.height
          })
          lastYPosition += 70 // Adjust this based on the message spacing
        }

        // Add the date as a separate message
        separatedMessages.push({
          text: dateMatch,
          x: messageObj.x,
          y: lastYPosition,
          width: messageObj.width,
          height: messageObj.height
        })
        lastYPosition += 70

        // The remaining text is now everything after the date
        remainingText = after
      } else {
        // No more dates, so add the remaining text as a message and break the loop
        if (remainingText.trim()) {
          separatedMessages.push({
            text: remainingText.trim(),
            x: messageObj.x,
            y: lastYPosition,
            width: messageObj.width,
            height: messageObj.height
          })
        }
        remainingText = '' // Stop the loop
      }
    }
  })

  // Remove empty messages
  const cleanMessages = separatedMessages.filter(message => message.text !== '')

  return cleanMessages
}

/**
 * Reove dates from receiver since they can only be found in sender
 * to avoid duplicates
 * @param {Array} messages 
 * @returns the clean messages
 */
export const checkAndRemoveExtra = (messagesReceiver) => {
  const cleanMessages = messagesReceiver.map(messageObj => {
    const { text } = messageObj

    // Loop through each regex and try to find a match
    for (const regex of allDateRegex) {
      const match = text.match(regex)

      if (match) {
        // If a match is found, remove everything from the match onward
        const matchIndex = text.indexOf(match[0])
        if (matchIndex !== -1) {
          return {
            ...messageObj,
            text: text.substring(0, matchIndex).trim() // Update the text field
          }
        }
      }
    }

    // Return the original message if no date match is found
    return messageObj
  })

  return cleanMessages
}

/**
 * Filter messages to keep only those before a "+ " message and messages with Lu and Distribué (fr and en)
 * @param {Array} messages are all the messages extracted from the images
 * @returns {Array} filteredMessages are the messages to keep
 */
export const truncateConversation = (messages) => {
  let foundPlusMessage = false
  
  const filteredMessages = messages.filter((message) => {
    const messageText = message.text.trim()

    // If a message starts with "+ ", mark the rest to be excluded
    if (
      messageText.toLowerCase() === '+' ||
      messageText.toLowerCase().startsWith('+ ') ||
      messageText.toLowerCase().startsWith('distribué +') ||
      messageText.toLowerCase().startsWith('distribue +')
    ) {
      foundPlusMessage = true
    }

    // Once a "+ " message is found, exclude subsequent messages
    if (foundPlusMessage) {
      return false
    }

    // Keep all messages before the "+ " message
    return true
  })

  return filteredMessages // No need for Promise wrapping
}

export const formatDateString = (date) => {
  const year = date.getUTCFullYear()
  const month = padZero(date.getUTCMonth() + 1)
  const day = padZero(date.getUTCDate())
  const hours = padZero(date.getUTCHours())
  const minutes = padZero(date.getUTCMinutes())
  const seconds = padZero(date.getUTCSeconds())
  const milliseconds = padMilliseconds(date.getUTCMilliseconds())
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`
}

export const parseDateToTimestampz = (dateStr) => {
  const monthsMap = {
    // fr
    'janv': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
    'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,

    'janvier': 0, 'février': 1, 'avril': 3, 'juillet': 6, 'août': 7,
    'septembre': 8, 'octobre': 9, 'novembre': 10, 'décembre': 11,

    // en
    'jan': 0, 'févr': 1, 'mars': 2, 'avr': 3, 'mai': 4, 'juin': 5,
    'juil': 6, 'août': 7, 'sept': 8, 'oct': 9, 'nov': 10, 'déc': 11,

    'january': 0, 'february': 1, 'april': 3, 'july': 6, 'august': 7,
    'september': 8, 'october': 9, 'november': 10, 'december': 11,
  }
  
  // Old date (ie: 5 mai 2023 à 18:09)
  if (/\d{1,2}\s+[\wéèàùûçÉÈÀÙÇ]{1,10}\s\d{4}\s(à|at)\s\d{1,2}\s?:\s?\d{2}/giu.test(dateStr)) {
    const [day, month, year, , time] = dateStr.split(' ')
    const monthIndex = monthsMap[month.replace('.', '')]

    const [hours, minutes] = time.replace(/\s/g, '').split(':')
    const date = new Date(Date.UTC(parseInt(year), monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))
    const formattedDate = formatDateString(date)
    
    return formattedDate
  }

  // Recent date (ie: mar. 5 mai à 18:09 or sam. 9 déc. à 19:37)
  // Formating to YYYY-mm-dd HH:MM:SS.mmm
  if (/\b[\wéèàùçÉÈÀÙÇ]{1,10}\.?\s\d{1,2}\s[\wéèàùûçÉÈÀÙÇ]{1,10}\.?\s(à|at)\s\d{1,2}\s?:\s?\d{2}\b/giu.test(dateStr)) {
    const [ , day, month, , time] = dateStr.split(' ')
    const monthIndex = monthsMap[month.replace('.', '')]
    const currentYear = new Date().getFullYear()

    const [hours, minutes] = time.split(':')
    const date = new Date(Date.UTC(currentYear, monthIndex, parseInt(day), parseInt(hours), parseInt(minutes), 0, 0))
    const formattedDate = formatDateString(date)
    
    return formattedDate
  }

  // Today date (ie: Aujourd’hui 18:09)
  if (/(Aujourd(’|')hui|Today)\s\d{1,2}\s?:\s?\d{2}/giu.test(dateStr)) {
    const timePart = dateStr.match(/\d{1,2}\s?:\s?\d{2}/)[0].replace(/\s/g, '')
    const today = new Date()
    
    const [hours, minutes] = timePart.split(':')
    today.setUTCHours(parseInt(hours), parseInt(minutes), 0, 0)
    const formattedDate = formatDateString(today)
    
    return formattedDate
  }
  
  return null
}

/**
 * Filters out "useless" parts of the message such as:
 * - Exact match for "iMessage"
 * - Ending with "Lu le ..." (case-insensitive) or "Distribué le ..." (case-insensitive)
 * The function removes the matching parts from the message but keeps the rest.
 *
 * @param {Array} messages - An array of message objects. Each message object should contain at least a `text` property.
 * @returns {Array} - The filtered array of messages with "useless" parts removed.
 */
export const handleRemovedUselessText = (messages) => {
  // Regex to detect 'Lu le ...' or 'Distribué le...' at the end of the message
  const luLeRegex = /\s?lu le\s.+$/i
  const distribueLeRegex = /\s?distribué le\s.+$/i

  // Filter and clean up the messages based on the conditions
  const filteredMessages = messages.map((message) => {
    let messageText = message.text.trim()

    // Remove 'iMessage' or 'Message' texts
    if (messageText.toLowerCase() === 'imessage' || messageText.toLowerCase() === 'message') {
      return null // Mark this message as null to filter it out later
    }

    // Remove the part that matches 'Lu le ...' or 'Distribué le ...'
    messageText = messageText.replace(luLeRegex, '').trim()
    messageText = messageText.replace(distribueLeRegex, '').trim()

    // Return the modified message if there's still text remaining
    return {
      ...message,
      text: messageText
    }
  }).filter(message => message && message.text !== '') // Filter out null or empty messages

  // Resolve the promise with the filtered messages
  return filteredMessages
}

/**
 * Final step of the parsing : identify each message by its date
 * @param {Array} mergedMessages for the messages classified and merged (doublons)
 * @returns {Array} the messages with isDate bool and createdAt timestampz
 */
export const handleDateMessages = (mergedMessages) => {
  let currentDate = null
  let firstDateIndex = null

  // First pass: find all date messages and assign dates going forward
  mergedMessages = mergedMessages.map((message, index) => {
    const messageText = message.text.trim()

    // Check if the message matches one of the valid date regex patterns
    const messageIsAdate = messageOldDatesRegex.test(messageText) ||
                            messageNormalDateRefex.test(messageText) ||
                            messageTodayDatesRegex.test(messageText) ||
                            messageYesterdayDatesRegex.test(messageText) ||
                            messageOrImessage.test(messageText)

    // If the message is a date
    if (messageIsAdate) {
      // Replace any formatting issues in the time part of the message
      message.text = message.text.replace(/(\s|):(\s|)/, ':')

      // Try to parse the date only if it's a valid date string
      currentDate = parseDateToTimestampz(message.text)

      if (currentDate) {
        message.isDate = true
        message.createdAt = currentDate

        if (firstDateIndex === null) {
          firstDateIndex = index
        }
      } else {
        // If parsing failed, handle the message as a non-date
        message.isDate = false
        message.createdAt = null
      }
    } else if (!messageIsAdate && currentDate) {
      // For non-date messages after a valid date, add 2 minutes to the current date
      const dateObj = new Date(currentDate)
      dateObj.setMinutes(dateObj.getMinutes() + 2)
      currentDate = dateObj.toISOString()
      message.createdAt = currentDate
    } else if (!messageIsAdate && !currentDate) {
      // If no date has been found yet, leave createdAt as null for now
      message.createdAt = null
    }

    return message
  })

  // Second pass: for messages before the first date, go backward by 2 minutes
  if (firstDateIndex !== null) {
    let reverseDate = new Date(mergedMessages[firstDateIndex].createdAt)

    for (let i = firstDateIndex - 1; i >= 0; i--) {
      const message = mergedMessages[i]
      reverseDate.setUTCMinutes(reverseDate.getUTCMinutes() - 2)
      message.createdAt = reverseDate.toISOString()
    }
  }
  
  // Resolve the promise with the updated messages
  return mergedMessages
}

export const handleResponseMessages = (mergedMessages) => {
  let currentDate = null
  let firstDateIndex = null
  const regex = /\d+\s*(réponse|réponses|answer|answers)\s*$/i

  // First pass: find all messages that match the regex and assign dates going forward
  mergedMessages = mergedMessages.map((message, index) => {
    if ( ! message?.text ) return message
    const messageText = message?.text.trim()

    // Check if the message matches the response regex pattern
    const messageIsAResponse = regex.test(messageText)

    // If the message is a response
    if (messageIsAResponse) {
      if (message.isDate) {
        message.isResponse = true
        message.createdAt = currentDate

        if (firstDateIndex === null) {
          firstDateIndex = index
        }
      } else {
        // If parsing failed, handle the message as a non-response
        message.isResponse = false
      }
    } else if (!messageIsAResponse && currentDate) {
      // For non-response messages after a valid response, add 2 minutes to the current date
      const dateObj = new Date(currentDate)
      dateObj.setUTCMinutes(dateObj.getUTCMinutes() + 2)
      currentDate = dateObj.toISOString()
      message.createdAt = currentDate
    } else if (!messageIsAResponse && !currentDate) {
      // If no response has been found yet, leave createdAt as null for now
      message.createdAt = null
    }

    return message
  })

  // Second pass: for messages before the first response, go backward by 2 minutes
  if (firstDateIndex !== null) {
    let reverseDate = new Date(mergedMessages[firstDateIndex].createdAt)

    for (let i = firstDateIndex - 1; i >= 0; i--) {
      const message = mergedMessages[i]
      reverseDate.setUTCMinutes(reverseDate.getUTCMinutes() - 2)
      message.createdAt = reverseDate.toISOString()
    }
  }

  return mergedMessages
}

export const checkIfResponse = (messages) => messages.map(message => {
  const regex = /\d+\s*(réponse|réponses|answer|answers).*/i
  const messageText = message.text.trim()

  // Check if the message ends with the specified pattern
  message.isResponse = regex.test(messageText)

  // If it's a response, remove the regex part from the message
  if (message.isResponse) {
    message.text = messageText.replace(regex, '').trim()
  }

  return message
})

/**
 * 
 * @param {Array} messages for arrays for messages
 * @returns {Array} messages merged if they are separated by less than 20px
 */
export const mergeSeparatedMessages = (messages) => {
  if (messages.length === 0) return []

  const mergedSeparatedMessages = []
  let currentMessage = { ...messages[0] } // Start with a copy of the first message

  for (let i = 1; i < messages.length; i++) {
    const nextMessage = messages[i]

    // Check if the y difference is less than 17 (adjusted range)
    const yDifference = currentMessage.y - nextMessage.y
    if (yDifference > 0 && yDifference < 17) {
      currentMessage.text += ' ' + nextMessage.text
      currentMessage.y = nextMessage.y
    } else {
      mergedSeparatedMessages.push(currentMessage)
      currentMessage = { ...nextMessage }
    }
  }

  // Push the last message
  mergedSeparatedMessages.push(currentMessage)

  // Resolve the promise with the merged result
  return mergedSeparatedMessages
}

/**
 * 
 * @param {Array} messages for classified messages
 * @returns {Array} all the messages without doublons
 */
export const mergeEqualMessages = (messages) => {
  if (messages.length === 0) return []

  const mergedEqualMessages = []
  let currentMessage = { ...messages[0] } // Start with a copy of the first message

  for (let i = 1; i < messages.length; i++) {
    const nextMessage = messages[i]

    // Merge messages that have the same y coordinate
    if (currentMessage.y === nextMessage.y) {
      currentMessage.text += ' ' + nextMessage.text
    } else {
      mergedEqualMessages.push(currentMessage)
      currentMessage = { ...nextMessage }
    }
  }

  // Push the last message
  mergedEqualMessages.push(currentMessage)

  // Resolve the promise with the result
  return mergedEqualMessages
}

/**
 * 
 * @param {Array} extractedData for the data extracted from the images
 * @returns {Array} messages classified by sender, and correct json structure
 */
export const classifyMessages = (extractedData) => {
  const classifiedMessages = []
  let messageNumber = 0

  extractedData.forEach(page => {
    page.textItems.forEach(item => {
      const [x, y] = [item.transform[4], item.transform[5]]
      const isSenderMessage = x > 200 // If text width position is over 200, it's a sender message

      classifiedMessages.push({
        text: item.str,
        y,
        isSenderMessage,
        isDate: false,
        order: messageNumber++,
      })
    })
  })

  // Resolve the promise with classified messages
  return classifiedMessages
}

export const hasSimilarMessage = (existingMessage, newMessage) => {
  const existingWords = existingMessage.split(/\s+/).length
  const newWords = newMessage.split(/\s+/).length

  // Check if the number of words is similar within a range of +/- 5 words
  const wordDifference = Math.abs(existingWords - newWords)
  if (wordDifference > 5) return false

  // Check if the first few words (to allow some tolerance) match
  const existingSnippet = existingMessage.split(/\s+/).slice(0, 10).join(' ')
  const newSnippet = newMessage.split(/\s+/).slice(0, 10).join(' ')

  return existingSnippet === newSnippet
}

export const isSimilarMessage = (messageA, messageB) => {
  const wordsA = messageA.text.split(/\s+/).slice(0, 20).join(' ')
  const wordsB = messageB.text.split(/\s+/).slice(0, 20).join(' ')

  // Return true if the first 20 words are identical
  return wordsA === wordsB
}

export const findSimilarMessages = (conversation) => {
  // Track messages to keep (initially all messages)
  const messagesToKeep = [...conversation]

  // Compare each message with every other message
  for (let i = 0; i < messagesToKeep.length; i++) {
    for (let j = i + 1; j < messagesToKeep.length; j++) {
      const messageA = messagesToKeep[i]
      const messageB = messagesToKeep[j]

      // If messages are similar, remove the shorter one
      if (isSimilarMessage(messageA, messageB)) {
        const messageAWords = messageA.text.split(/\s+/).length
        const messageBWords = messageB.text.split(/\s+/).length

        // Keep the longer message or the one with more words
        if (messageAWords >= messageBWords) {
          messagesToKeep.splice(j, 1)
          j-- // Adjust index after removal
        } else {
          messagesToKeep.splice(i, 1)
          i-- // Adjust index after removal
          break // Exit inner loop since messageA was removed
        }
      }
    }
  }

  return messagesToKeep
}

export function correctIsSenderMessage(messages) {
  for (let i = 1; i < messages.length; i++) {
    if (!messages[i].isSenderMessage) {
      let j = i - 1
      while (j >= 0 && messages[j].isResponse) {
        messages[i].isSenderMessage = false
        j--
      }
    }
  }

  // Handle previous messages if they need to be set to false
  for (let i = messages.length - 2; i >= 0; i--) {
    if (messages[i].isResponse && !messages[i + 1].isSenderMessage) {
      messages[i].isSenderMessage = false
    }
  }

  return messages
}

/**
 * assign a createdAt timestamp to a message that doesn't currently have one,
 * by looking for the next message in the array that does have a createdAt or a valid date (isDate)
 * @param {*} i index of the message in the for loop
 * @param {*} messages all messages from the conversation
 * @returns all messages
 */
export const assignDateToMessage = (i, messages) => {
  const message = messages[i]
  let nextMessageIndex = i + 1
  let nextMessage = messages[nextMessageIndex]

  // Find the next message with a valid createdAt or a date
  while (nextMessage && !nextMessage.createdAt && !nextMessage.isDate) {
    nextMessageIndex++
    nextMessage = messages[nextMessageIndex]
  }

  if (nextMessage) {
    const dateObj = new Date(nextMessage.createdAt)
    dateObj.setUTCMinutes(dateObj.getUTCMinutes() - 2)
    message.createdAt = formatDateString(dateObj)
  } else {
    message.createdAt = formatDateString(new Date())
  }

  return message
}

/**
 * Gets all the words and form full sentences based on x,y 
 * @param {Array} words 
 * @returns 
 */
export const getMessagesFromImage = (words) => {
  if ( !words || words.length === 0 ) return []
  
  const Messages = []

  let currentMessage = {
    text: '',
    x: words[0].baseline.x0,
    y: words[0].baseline.y0,
    width: words[0].baseline.x1 - words[0].baseline.x0,
    height: words[0].baseline.y1 - words[0].baseline.y0
  }

  words.forEach((word, index) => {
    if (index === 0) {
      // Start with the first word
      currentMessage.text = word.text
    } else {
      // Check if the difference in y positions indicates a new message
      const yDifference = word.baseline.y0 - words[index - 1].baseline.y0
      if (yDifference > 70) {
        // Finish the current message and start a new one
        Messages.push(currentMessage)
        currentMessage = {
          text: word.text,
          x: word.baseline.x0,
          y: word.baseline.y0,
          width: word.baseline.x1 - word.baseline.x0,
          height: word.baseline.y1 - word.baseline.y0,
        }
      } else {
        // Continue adding words to the current message
        currentMessage.text += ' ' + word.text
        // Adjust the bounding box if needed
        currentMessage.width = Math.max(currentMessage.width, word.baseline.x1 - currentMessage.x)
        currentMessage.height = Math.max(currentMessage.height, word.baseline.y1 - currentMessage.y)
      }
    }
  })

  // Push the last message
  Messages.push(currentMessage)

  return Messages
}